// src/store/modules/org.js
import apiClient from '@/axios';

const state = {
  suppliers: [],
  dropdowns: {},
  lookUpData: [],
  lookUpElectricData: [],
  suppliersWithPrice: [],
  suppliersName: [],
};

const getters = {
  getSuppliers: (state) => state.suppliers,
  getDropdowns: (state) => state.dropdowns,
  getlookUpData: (state) => state.lookUpData,
  getlookUpElectricData: (state) => state.lookUpElectricData,
  getsuppliersWithPrice: (state) => state.suppliersWithPrice,
  getsuppliersName: (state) => state.suppliersName,
};

const mutations = {
  SET_SUPPLIERS(state, data) {
    state.suppliers = data;
  },
  SET_DROPDOWNS(state, data) {
    state.dropdowns = data;
  },
  SET_LOOKUP_DATA(state, data) {
    state.lookUpData = data;
  },
  SET_LOOKUP_ELECTRIC_DATA(state, data){
    state.lookUpElectricData = data;
  },
  SET_SUPPLIERS_PRICE_DATA(state, data){
    state.suppliersWithPrice = data;
  },
  SET_SUPPLIERS_NAME(state, data){
    state.suppliersName = data;
  }
};

const actions = {
  async fetchSuppliers({ commit }) {
    try {
      const response = await apiClient.get('/quote/settings');
      if(response){
        commit('SET_SUPPLIERS', response.data.data.supplier);
        commit('SET_DROPDOWNS', response.data.data.dropdowns);
        return response.data.data;
      }
    } catch (error) {
      console.error('Error fetching SET_SUPPLIERS:', error);
    }
  },

  async fetchLookupData({ commit }, paramsData) {
    try {
      // Make a POST request with paramsData
      const response = await apiClient.post('/lookup/site', paramsData);
      if (response) {
        commit('SET_LOOKUP_DATA', response.data.data.supplier); // Commit the fetched data
        return response.data.data; // Return the data for further use
      }
    } catch (error) {
      console.error('Error fetching lookup', error); // Log any errors
    }
  },

  async fetchSearchPropertyData({ commit }, paramsData) {
    console.log(paramsData, 'addressId');
    
    try {
      // Make a POST request with paramsData
      const response = await apiClient.post('/lookup/energy/property/search-by-address-id', paramsData);
      console.log(response, 'sadasdasd');
      
      if (response) {
        commit('SET_LOOKUP_ELECTRIC_DATA', response.data.data);
        return response.data.data;
      }
    } catch (error) {
      console.error('Error fetching lookup', error);
    }
  },

  async getSupplierPrice({ commit }, formData) {
    console.log(formData, 'formData');
  
    try {
      // Make a POST request with formData
      const response = await apiClient.post('/power/pricing', formData);
      console.log(response, 'ssssss');
  
      let suppliersPriceData = [];
  
      if (response.data.data.GetGasRatesResult) {
        suppliersPriceData = response.data.data.GetGasRatesResult.Rates;
      } else if (response.data.data.GetElectricRatesResult) {
        suppliersPriceData = response.data.data.GetElectricRatesResult.Rates;
      }
      // Filter suppliers where AnnualPrice is not null or zero
      const filteredSuppliers = suppliersPriceData.filter(supplier => supplier.AnnualPrice);
      commit('SET_SUPPLIERS_PRICE_DATA', filteredSuppliers);
      console.log(filteredSuppliers, "filteredSuppliers");
      const supplierPriceData = new Set(filteredSuppliers.map(supplier => supplier.Supplier));
      console.log(supplierPriceData, "supplierPriceData");
      commit('SET_SUPPLIERS_NAME', supplierPriceData )
      return filteredSuppliers; // Return the filtered data
    } catch (error) {
      console.error('Error fetching lookup', error);
    }
  },
  async downloadPdf({ commit }, paramsData) {
    try {
      // Make a POST request with paramsData
      const response = await apiClient.post('power/export-price-pdf', paramsData);
      if (response.status === 200 ) {
        // commit('SET_LOOKUP_DATA', response.data.data.supplier); // Commit the fetched data
        return response; // Return the data for further use
      }
    } catch (error) {
      console.error('Error fetching export-price-pdf', error); // Log any errors
    }
  },
  async downloadExcel({ commit }, excelData) {
    try {
      // Make a POST request with paramsData
      const response = await apiClient.post('power/export-price-excel', excelData);
      if (response.status === 200 ) {
        return response; // Return the data for further use
      }
    } catch (error) {
      console.error('Error fetching export-price-excel', error); // Log any errors
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
