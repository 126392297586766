<template>
<index-loader></index-loader>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>
<!-- Page Wrapper -->
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <dashboard-header :title="title" @filter-toggle="handleFilterToggle" />

        <div class="row position-relative m-0">
          <!-- <button type="btn" class="btn btn-primary filter_btn"><i class="ti ti-arrow-bar-to-left"></i></button> -->
          <div class="user-accordion-item">
            <a href="javascript:;" class="accordion-wrap" data-bs-toggle="collapse" data-bs-target="#basicDetails"><span><i class="ti ti-details"></i></span>Gas / Electricity
              Details</a>
            <div class="accordion-collapse collapse show" id="basicDetails" data-bs-parent="#list-accord">
              <div class="content-collapse m-0 p-0">
                <div class="col-md-12">
                  <div class="contact-tab-wrap mb-0 px-2">
                    <ul class="contact-nav nav">
                      <li class="col-md-1 me-0">
                        <a href="javascript:;" data-bs-toggle="tab" data-bs-target="#gas" :class="{ active: activeTab === 'gas' }" @click="activeTab = 'gas'">Gas</a>
                      </li>
                      <li class="col-md-1 me-0">
                        <a href="javascript:;" data-bs-toggle="tab" data-bs-target="#electricity" :class="{ active: activeTab === 'electricity' }" @click="activeTab = 'electricity'">Electricity</a>
                      </li>
                    </ul>
                    <button type="button" class="btn btn-primary py-2 m-2" @click="compare">
                      Compare
                    </button>
                  </div>

                  <!-- Tab Content -->
                  <div class="contact-tab-view mb-0 p-2">
                    <div class="tab-content pt-0">
                      <!-- gas -->
                      <div class="tab-pane active show" id="gas">
                        <quoting-gas @submit-form="submitFormData" :isNotValid="isNotValid"></quoting-gas>
                      </div>

                      <!-- electricity -->
                      <div class="tab-pane fade" id="electricity">
                        <quoting-electricity @submit-elctric-form="submitFormData" :isElecNotValid="isElecNotValid"></quoting-electricity>
                      </div>
                    </div>
                  </div>
                  <!-- /Tab Content -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 p-0">
            <div class="filter-panel" :class="{ open: isFilterOpen }">
              <button type="btn" class="btn btn-primary filter_btn p-1" @click="handleFilterToggle"><i class="ti ti-arrow-bar-to-right"></i></button>
              <refine-filter :uniqueSuppliers="filteredSuppliers" @update-selected-suppliers="UpdateSuppliers" @update:isYear="updateIsYear" @updateyears="updateSelectedYears" @min-months="handleMinMonths" @max-months="handleMaxMonths"></refine-filter>
            </div>
          </div>
          <div class="col-md-12 p-0 position-relative">
            <!-- v-if="activeTab === 'gas'" -->
            <quoting-data v-if="filteredSuppliersRateList.length > 0" :suppliersRateList="filteredSuppliersRateList" :isElectric="isElectric" :selectedSuppliers="selectedSuppliers" :isYear="isYear" :selectedYears="selectedYears" :minMonths="minMonths" :maxMonths="maxMonths" :formData="formData"></quoting-data>
          </div>
          <!-- <div
              class="col-md-12 p-0 position-relative"
              v-if="activeTab === 'electricity'"
            >
              <quoting-elec-data
                v-if="elecSuppliersRateList.length > 0"
                :elecSuppliersRateList="elecSuppliersRateList"
                :isElectric="isElectric"
                :selectedSuppliers="selectedSuppliers"
              ></quoting-elec-data>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Page Wrapper -->
</template>

<script>
import {
  ref
} from "vue";
import {
  onMounted,
  computed
} from "vue";
import {
  useStore,
  mapGetters
} from "vuex";
import {
  message
} from "ant-design-vue";
export default {
  data() {
    return {
      title: "Quoting",
    };
  },
  setup() {
    const store = useStore();
    const gasformData = ref(null);
    const elecformData = ref(null);
    const formData = ref(null);
    const isFilterOpen = ref(false);
    const suppliersRateList = ref([]);
    const elecSuppliersRateList = ref([]);
    const isElectric = ref(false);
    const activeTab = ref("gas");
    const uniqueSuppliers = ref([]);
    const uniqueElectricSuppliers = ref([]);
    const isYear = ref(false);
    const selectedYears = ref([]);
    const selectedSuppliers = ref([]);
    const isNotValid = ref(false);
    const isElecNotValid = ref(false);
    const minMonths = ref(12);
    const maxMonths = ref(60);
    // Methods to handle emitted events
    const handleMinMonths = (newMin) => {
      minMonths.value = newMin;
    };

    const handleMaxMonths = (newMax) => {
      maxMonths.value = newMax;
    };

    const handleFilterToggle = () => {
      isFilterOpen.value = !isFilterOpen.value;
    };
    const updateSelectedYears = (years) => {
      selectedYears.value = years;
    };
    const updateIsYear = (newValue) => {
      isYear.value = newValue;
    };

    const submitFormData = (data) => {
      if (data.mainProductService === "gas") {
        gasformData.value = data;
      } else if (data.mainProductService === "elec") {
        elecformData.value = data;
      }
    };
    const UpdateSuppliers = (suppliers) => {
      selectedSuppliers.value = suppliers;
    };

    const compare = async () => {
      if (activeTab.value === "gas") {
        formData.value = gasformData.value;
        if (formData.value.quoteDetails.PostCode === '' || formData.value.quoteDetails.GasSupply.Consumption.Amount === 0 || formData.value.quoteDetails.CurrentSupplier === '' || formData.value.quoteDetails.GasSupply.ContractRenewalDate === '' || formData.value.quoteDetails.Settings[1].value === '0' || formData.value.quoteDetails.Uplift === null) {
          isNotValid.value = true;
          return;
        } else {
          isNotValid.value = false;
          const dateFormate = formData.value.quoteDetails.GasSupply.ContractRenewalDate;
          const convertedDate = new Date(dateFormate).toISOString().split('T')[0];
          localStorage.setItem('renewDate', convertedDate);
          localStorage.setItem('annual_usage', formData.value.quoteDetails.GasSupply.Consumption.Amount);
          const response = await store.dispatch(
            "price/getSupplierPrice",
            formData.value
          );
          suppliersRateList.value = response;
          uniqueSuppliers.value = [
            ...new Set(response.map((supplier) => supplier.Supplier)),
          ];
        }
      } else if (activeTab.value === "electricity") {
        formData.value = elecformData.value;
        if (formData.value.quoteDetails.PostCode === '' || formData.value.quoteDetails.ElectricSupply.MPANTop === '' ||
          formData.value.quoteDetails.ElectricSupply.MPANBottom === '' || formData.value.quoteDetails.ElectricSupply.DayConsumption.Amount === 0 || formData.value.quoteDetails.CurrentSupplier === '' || formData.value.quoteDetails.ElectricSupply.ContractRenewalDate === '' || formData.value.quoteDetails.Settings[1].value === '0' || formData.value.quoteDetails.Uplift === null ) {
          isElecNotValid.value = true;
          return;
        } else {

          const elecdateFormate = formData.value.quoteDetails.ElectricSupply.ContractRenewalDate;
          const convertedElecDate = new Date(elecdateFormate).toISOString().split('T')[0];
          localStorage.setItem('elec_renewDate', convertedElecDate);
          localStorage.setItem('elec_annual_usage', formData.value.quoteDetails.ElectricSupply.DayConsumption.Amount);
          isElecNotValid.value = false;
          const response = await store.dispatch(
            "price/getSupplierPrice",
            formData.value
          );
          elecSuppliersRateList.value = response;
          uniqueElectricSuppliers.value = [
            ...new Set(response.map((supplier) => supplier.Supplier)),
          ];
        }
      }

      if (formData.value.mainProductService === "elec") {
        isElectric.value = true;
      } else {
        isElectric.value = false;
      }
    };

    const filteredSuppliers = computed(() => {
      return activeTab.value === "gas" ? uniqueSuppliers.value : uniqueElectricSuppliers.value;
    });

    const filteredSuppliersRateList = computed(() => {
      return activeTab.value === "gas" ? suppliersRateList.value : elecSuppliersRateList.value;
    });
    onMounted(async () => {});

    return {
      isNotValid,
      isElecNotValid,
      handleMinMonths,
      handleMaxMonths,
      minMonths,
      maxMonths,
      updateIsYear,
      selectedYears,
      updateSelectedYears,
      isYear,
      activeTab,
      gasformData,
      elecformData,
      isElectric,
      formData,
      compare,
      isFilterOpen,
      handleFilterToggle,
      submitFormData,
      suppliersRateList,
      elecSuppliersRateList,
      uniqueSuppliers,
      UpdateSuppliers,
      selectedSuppliers,
      uniqueElectricSuppliers,
      filteredSuppliers,
      filteredSuppliersRateList
    };
  },
};
</script>

<style scoped>
.filter-panel {
  position: fixed;
  right: -400px;
  top: 57px;
  width: 290px;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 9999;
  height: 100%;
}

.filter-panel.open {
  right: 0;
}

.filter_btn {
  position: absolute;
  left: -20px;
}
</style>
