<template>
<a-table class="table dataTable no-footer" :columns="columns" :data-source="data" :row-selection="rowSelection" :pagination="pagination" @change="handleTableChange">
  <template #bodyCell="{ column, record }">
    <template v-if="column.key === 'number'">
      <router-link :to="{ path: '/crm/edit-contract', query: { territory_id: record.territory_id, org_Id: record.orgId, contract_Id: record.key }}" class="profile-split d-flex flex-column">
          {{ record.number }}
        </router-link>
    </template>
    <template v-else-if="column.key === 'org_name'">
      <router-link :to="{ path: '/crm/organisation-details', query: { orgId: record.orgId }}" class="profile-split d-flex flex-column">
          {{ record.org_name }}
        </router-link>
    </template>
    
    <template v-else-if="column.key === 'action'">
      <div class="dropdown table-action">
        <a href="javascript:;" class="action-icon" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
        <div class="dropdown-menu dropdown-menu-right">
          <router-link :to="{ path: '/crm/edit-contract', query: { territory_id: record.territory_id, org_Id: record.orgId, contract_Id: record.key }}"><i class="ti ti-edit text-blue"></i> Edit</router-link>
          <!-- <a class="dropdown-item edit-popup" href="javascript:;"
              ><i class="ti ti-edit text-blue"></i> Edit</a
            ><a
              class="dropdown-item"
              href="javascript:;"
              data-bs-toggle="modal"
              data-bs-target="#delete_contact"
              ><i class="ti ti-trash text-danger"></i> Delete</a
            ><a class="dropdown-item" href="javascript:;"
              ><i class="ti ti-clipboard-copy text-blue-light"></i> Clone</a
            > -->
        </div>
      </div>
    </template>
  </template>
</a-table>
</template>

<script>
import {
  ref,
  onMounted,
  watchEffect
} from 'vue';
import {
  useStore
} from "vuex";
import {
  useRouter,
  useRoute
} from 'vue-router';
import contract from '@/store/modules/contract';

const columns = [{
    title: "Number",
    dataIndex: "number",
    key: "number",
    sorter: {
      compare: (a, b) => {
        a = a.number;
        b = b.number;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Org. Name",
    dataIndex: "org_name",
    key: "org_name",
    sorter: {
      compare: (a, b) => {
        a = a.org_name;
        b = b.org_name;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "Description",
    sorter: {
      compare: (a, b) => {
        a = a.description;
        b = b.description;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Org.  Responsible",
    dataIndex: "org_res",
    sorter: {
      compare: (a, b) => {
        a = a.org_res;
        b = b.org_res;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: {
      compare: (a, b) => {
        a = a.status;
        b = b.status;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date of Sale",
    dataIndex: "date_of_sale",
    sorter: {
      compare: (a, b) => {
        a = a.date_of_sale;
        b = b.date_of_sale;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "SC Forecast Total Commission",
    dataIndex: "total_commission",
    sorter: {
      compare: (a, b) => {
        a = a.total_commission;
        b = b.total_commission;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Created",
  //   dataIndex: "create",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.create;
  //       b = b.create;
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Action",
    dataIndex: "Action",
    key: "action",
    sorter: true,
  },
];

export default {
  props: {
    searchContractQuery: String,
    filters: Object,
  },
  data() {
    return {
      columns,
      data: [],
      selectedUserId: null,
      showDeleteModal: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      rowSelection: {
        onChange: () => {},
        onSelect: () => {},
        onSelectAll: () => {},
      },
    };
  },

  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const data = ref([]);
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
    });

    const fetchContracts = async () => {
      const params = {
        search: props.searchContractQuery, // Use the correct prop
        page: pagination.value.current,
        pageSize: pagination.value.pageSize,
      };

      const response = await store.dispatch('contract/fetchContracts', params);
      if (response.contracts.length > 0) {
        data.value = response.contracts.map((contract) => ({
          key: contract.id,
          number: contract.contract_number,
          territory_id: contract.opportunity.territory_id,
          orgId: contract.organization.id,
          org_name: contract.organization.name,
          org_res: contract.responsible_user_name,
          description: contract.contract_description,
          status: contract.overall_status,
          date_of_sale: contract.date_of_sale,
          total_commission: contract.opportunity.sc_forecast_total_commission,
          create: contract.date,
        }));
        pagination.value.total = response.total;
      }
    };

    const handleTableChange = (newPagination) => {
      pagination.value.current = newPagination.current;
      pagination.value.pageSize = newPagination.pageSize;
      fetchContracts();
    };

    const openEditModal = (id) => {
      router.push({
        path: "/crm/create-organisation",
        query: {
          orgId: id
        },
      });
    };

    watchEffect(() => {
      fetchContracts();
    });

    return {
      data,
      pagination,
      fetchContracts,
      handleTableChange,
      openEditModal,
    };
  },
};
</script>
