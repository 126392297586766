import { createRouter, createWebHistory } from 'vue-router';

import Login from '@/views/pages/pages/authentication/auth-login.vue'
import Login_2 from '@/views/pages/pages/authentication/login-2.vue'
import Login_3 from '@/views/pages/pages/authentication/login-3.vue'
import Register from '@/views/pages/pages/authentication/auth-register.vue'
import Register_2 from '@/views/pages/pages/authentication/register-2.vue'
import Register_3 from '@/views/pages/pages/authentication/register-3.vue'
import Forgot_Password from '@/views/pages/pages/authentication/forgot-password.vue'
import Forgot_Password_2 from '@/views/pages/pages/authentication/forgot-password-2.vue'
import Forgot_Password_3 from '@/views/pages/pages/authentication/forgot-password-3.vue'
import Reset_Password from '@/views/pages/pages/authentication/reset-password.vue'
import Reset_Password_2 from '@/views/pages/pages/authentication/reset-password-2.vue'
import Reset_Password_3 from '@/views/pages/pages/authentication/reset-password-3.vue'
import Email_Verification from '@/views/pages/pages/authentication/email-verification.vue'
import Email_Verification_2 from '@/views/pages/pages/authentication/email-verification-2.vue'
import Email_Verification_3 from '@/views/pages/pages/authentication/email-verification-3.vue'
import Two_Step_Verification from '@/views/pages/pages/authentication/two-step-verification.vue'
import Two_Step_Verification_2 from '@/views/pages/pages/authentication/two-step-verification-2.vue'
import Two_Step_Verification_3 from '@/views/pages/pages/authentication/two-step-verification-3.vue'
import Success from '@/views/pages/pages/authentication/auth-success.vue'
import Success_2 from '@/views/pages/pages/authentication/success-2.vue'
import Success_3 from '@/views/pages/pages/authentication/success-3.vue'
import Lock_Screen from '@/views/pages/pages/authentication/lock-screen.vue'
import Crm_Error from '@/views/pages/pages/error/crm-error.vue'
import Error_404 from '@/views/pages/pages/error/error-404.vue'
import Error_500 from '@/views/pages/pages/error/error-500.vue'
import Blank_Page from '@/views/pages/pages/blank-page.vue'
import Coming_Soon from '@/views/pages/pages/coming-soon.vue'
import Under_Maintenance from '@/views/pages/pages/under-maintenance.vue'
import CRM from '@/views/pages/crm/crm-index.vue'
import ContactsList from '@/views/pages/crm/contacts/contacts-list.vue'
import Crm_Support from '@/views/pages/support/crm-support.vue'
import Contact_Messages from '@/views/pages/support/contact-messages.vue'
import Crm_Tickets from '@/views/pages/support/crm-tickets.vue'
import Crm_Settings from '@/views/pages/settings/crm-settings.vue'
import Crm_Profile from '@/views/pages/settings/general-settings/crm-profile.vue'
import Crm_Security from '@/views/pages/settings/general-settings/crm-security.vue'
import Crm_Notifications from '@/views/pages/settings/general-settings/crm-notifications.vue'
import Connected_Apps from '@/views/pages/settings/general-settings/connected-apps.vue'
import Website_Settings from '@/views/pages/settings/data-import/website-settings.vue'
import Upload_file from '@/views/pages/settings/data-import/upload-file.vue'
import Users from '@/views/pages/settings/users/users.vue'
import Users_list from '@/views/pages/settings/users/users-list.vue'
import Create_user from '@/views/pages/settings/users/create-user.vue'
import Data_status from '@/views/pages/settings/data-import/import-data-status.vue'
import Get_quoting from '@/views/pages/quoting/get-quoting.vue'
import Quoting from '@/views/pages/quoting/quoting-index.vue'


import Map_data from '@/views/pages/settings/data-import/map-data.vue'
import Assign_data from '@/views/pages/settings/data-import/assign-data.vue'
import Validate from '@/views/pages/settings/data-import/validate.vue'
import Appearance from '@/views/pages/settings/data-import/appearance-settings.vue'
import Create_mode from '@/views/pages/settings/data-import/create-mode.vue'
import Error_logs from '@/views/pages/settings/data-import/error-logs.vue'
// import App_Settings from '@/views/pages/settings/app-settings/app-settings.vue'
// import Invoice_Settings from '@/views/pages/settings/app-settings/invoice-settings.vue'
// import Printers from '@/views/pages/settings/app-settings/printers-settings.vue'
// import Custom_Fields from '@/views/pages/settings/app-settings/custom-fields.vue'
// import System_Settings from '@/views/pages/settings/system-settings/system-settings.vue'
// import Email_Settings from '@/views/pages/settings/system-settings/email-settings.vue'
// import Sms_Gateways from '@/views/pages/settings/system-settings/sms-gateways.vue'
// import Gdpr_Cookies from '@/views/pages/settings/system-settings/gdpr-cookies.vue'
// import Financial_Settings from '@/views/pages/settings/financial-settings/financial-settings.vue'
// import Payment_Gateways from '@/views/pages/settings/financial-settings/payment-gateways.vue'
// import Bank_Accounts from '@/views/pages/settings/financial-settings/bank-accounts.vue'
// import Tax_Rates from '@/views/pages/settings/financial-settings/tax-rates.vue'
// import Currencies from '@/views/pages/settings/financial-settings/currencies-settings.vue'
// import Other_Settings from '@/views/pages/settings/other-settings/other-settings.vue'
// import Storage_Settings from '@/views/pages/settings/other-settings/storage-settings.vue'
// import Ban_Ip_Address from '@/views/pages/settings/other-settings/ban-ip-address.vue'
import ContactGrid from '@/views/pages/crm/contacts/contact-grid.vue'
import ContactDetails from '@/views/pages/crm/contacts/contact-details/contact-details.vue'
import CompaniesList from '@/views/pages/crm/organisation/organisation-list.vue';
import CreateOrganisation from '@/views/pages/crm/organisation/create-organisation.vue';

import PersonsList from '@/views/pages/crm/person/person-list.vue';
import CreatePerson from '@/views/pages/crm/person/create-person.vue';

import Crm_User from '@/views/pages/user/crm-user.vue'
import Manage_Users from '@/views/pages/user/manage-users.vue'
import Roles_Permissions from '@/views/pages/user/roles-permissions.vue'
import Crm_Permission from '@/views/pages/user/crm-permission.vue'
import Delete_Request from '@/views/pages/user/delete-request.vue'
import Crm_Content from '@/views/pages/content/crm-content.vue'
import Crm_Pages from '@/views/pages/content/crm-pages.vue'
import Crm_Testimonials from '@/views/pages/content/crm-testimonials.vue'
import Crm_Faq from '@/views/pages/content/crm-faq.vue'
import Crm_Location from '@/views/pages/content/location/crm-location.vue'
import Crm_Countries from '@/views/pages/content/location/crm-countries.vue'
import Crm_States from '@/views/pages/content/location/crm-states.vue'
import Crm_Cities from '@/views/pages/content/location/crm-cities.vue'
import Crm_Membership from '@/views/pages/membership/crm-membership.vue'
import Membership_Plans from '@/views/pages/membership/membership-plans.vue'
import Membership_Addons from '@/views/pages/membership/membership-addons.vue'
import Membership_Transactions from '@/views/pages/membership/membership-transactions.vue'
import Crm_Index from '@/views/pages/crm-settings/crm-index.vue'
import Crm_Calls from '@/views/pages/crm-settings/crm-calls.vue'
import Crm_Industry from '@/views/pages/crm-settings/crm-industry.vue'
import Contact_Stage from '@/views/pages/crm-settings/contact-stage.vue'
import Lost_Reason from '@/views/pages/crm-settings/lost-reason.vue'
import Crm_Sources from '@/views/pages/crm-settings/crm-sources.vue'
import Crm_Report from '@/views/pages/report/crm-report.vue'
import Lead_Report from '@/views/pages/report/lead-reports/lead-reports.vue'
import Deal_Report from '@/views/pages/report/deal-reports/deal-reports.vue'
import Contact_Report from '@/views/pages/report/contact-reports/contact-reports.vue'
import Company_Report from '@/views/pages/report/company-reports/company-reports.vue'
import Project_Report from '@/views/pages/report/project-reports/project-reports.vue'
import Task_Report from '@/views/pages/report/task-reports/task-reports.vue'


import Tables from '@/views/pages/uiinterface/tables/ui-tables.vue'
import Tables_Basic from '@/views/pages/uiinterface/tables/tables-basic.vue'
import Data_Tables from '@/views/pages/uiinterface/tables/data-tables.vue'
import Chartapex from '@/views/pages/uiinterface/charts/apex/chart-apex.vue'
import Chartc3 from '@/views/pages/uiinterface/charts/c3/chart-c3.vue'
import Chartflot from '@/views/pages/uiinterface/charts/flot/chart-flot.vue'
import Chartjs from '@/views/pages/uiinterface/charts/js/chart-js.vue'
import Chartmorris from '@/views/pages/uiinterface/charts/morris/chart-morris.vue'
import Charts from '@/views/pages/uiinterface/charts/ui-charts.vue'
import Forms from '@/views/pages/uiinterface/forms/ui-forms.vue'
import Formbasicinput from '@/views/pages/uiinterface/forms/form-elements/formbasic/formbasic-input.vue'
import Formcheckboxradios from '@/views/pages/uiinterface/forms/form-elements/form-checkbox-radios.vue'
import Formgridgutters from '@/views/pages/uiinterface/forms/form-elements/form-grid-gutters.vue'
import Formselect from '@/views/pages/uiinterface/forms/form-elements/form-select.vue'
import FormInput from '@/views/pages/uiinterface/forms/form-elements/forminput/form-input.vue'
import Formmask from '@/views/pages/uiinterface/forms/form-elements/formmask/formmask.vue'
import Formfileupload from '@/views/pages/uiinterface/forms/form-elements/formfile-upload.vue'
import FormHorizontal from '@/views/pages/uiinterface/forms/layouts/form-horizontal.vue'
import Formvertical from '@/views/pages/uiinterface/forms/layouts/form-vertical.vue'
import Formfloatinglabels from '@/views/pages/uiinterface/forms/layouts/form-floating-labels.vue'
import Formvalidation from '@/views/pages/uiinterface/forms/form-validation.vue'
import Formselect2 from '@/views/pages/uiinterface/forms/form-select2.vue'
import Formwizard from '@/views/pages/uiinterface/forms/form-wizard.vue'
import Advancedui from '@/views/pages/uiinterface/advancedUI/advanced-ui.vue'
import UI_Ribbon from '@/views/pages/uiinterface/advancedUI/ui-ribbon.vue'
import UI_Clipboard from '@/views/pages/uiinterface/advancedUI/ui-clipboard.vue'
import UI_Drag_Drop from '@/views/pages/uiinterface/advancedUI/ui-drag-drop.vue'
import UI_Text_Editor from '@/views/pages/uiinterface/advancedUI/ui-text-editor.vue'
import UI_Counter from '@/views/pages/uiinterface/advancedUI/ui-counter.vue'
import UI_Scrollbar from '@/views/pages/uiinterface/advancedUI/ui-scrollbar.vue'
import UI_Rating from '@/views/pages/uiinterface/advancedUI/ui-rating.vue'
import UI_Stickynote from '@/views/pages/uiinterface/advancedUI/ui-stickynote.vue'
import UI_Timeline from '@/views/pages/uiinterface/advancedUI/ui-timeline.vue'
import BaseUi from '@/views/pages/uiinterface/baseui/base-ui.vue'
import UI_Alerts from '@/views/pages/uiinterface/baseui/ui-alerts.vue'
import UI_Accordion from '@/views/pages/uiinterface/baseui/ui-accordion.vue'
import UI_Avatar from '@/views/pages/uiinterface/baseui/ui-avatar.vue'
import UI_Badges from '@/views/pages/uiinterface/baseui/ui-badges.vue'
import UI_Borders from '@/views/pages/uiinterface/baseui/ui-borders.vue'
import UI_Buttons from '@/views/pages/uiinterface/baseui/ui-buttons.vue'
import UI_Buttons_group from '@/views/pages/uiinterface/baseui/ui-buttons-group.vue'
import UI_Breadcrumb from '@/views/pages/uiinterface/baseui/ui-breadcrumb.vue'
import UI_Cards from '@/views/pages/uiinterface/baseui/ui-cards.vue'
import UI_Carousel from '@/views/pages/uiinterface/baseui/carousel/ui-carousel.vue'
import UI_Colors from '@/views/pages/uiinterface/baseui/ui-colors.vue'
import UI_Dropdowns from '@/views/pages/uiinterface/baseui/ui-dropdowns.vue'
import UI_Grid from '@/views/pages/uiinterface/baseui/ui-grid.vue'
import UI_Images from '@/views/pages/uiinterface/baseui/ui-images.vue'
import UI_Lightbox from '@/views/pages/uiinterface/baseui/lightbox/ui-lightbox.vue'
import UI_Media from '@/views/pages/uiinterface/baseui/ui-media.vue'
import UI_Modals from '@/views/pages/uiinterface/baseui/ui-modals.vue'
import UI_Offcanvas from '@/views/pages/uiinterface/baseui/ui-offcanvas.vue'
import UI_Pagination from '@/views/pages/uiinterface/baseui/ui-pagination.vue'
import UI_Popovers from '@/views/pages/uiinterface/baseui/ui-popovers.vue'
import UI_Progress from '@/views/pages/uiinterface/baseui/ui-progress.vue'
import UI_Placeholders from '@/views/pages/uiinterface/baseui/ui-placeholders.vue'
import UI_Rangeslider from '@/views/pages/uiinterface/baseui/ui-rangeslider.vue'
import UI_Spinner from '@/views/pages/uiinterface/baseui/ui-spinner.vue'
import UI_Sweetalerts from '@/views/pages/uiinterface/baseui/ui-sweetalerts.vue'
import UI_Nav_Tabs from '@/views/pages/uiinterface/baseui/ui-nav-tabs.vue'
import UI_Tooltips from '@/views/pages/uiinterface/baseui/ui-tooltips.vue'
import UI_Typography from '@/views/pages/uiinterface/baseui/ui-typography.vue'
import UI_Video from '@/views/pages/uiinterface/baseui/ui-video.vue'
import Icons from '@/views/pages/uiinterface/icons/ui-icons.vue'
import UI_Iconfontawesome from "@/views/pages/uiinterface/icons/icon-fontawesome.vue";
import UI_Iconfeather from "@/views/pages/uiinterface/icons/icon-feather.vue";
import UI_Iconionic from "@/views/pages/uiinterface/icons/icon-ionic.vue";
import UI_Iconmaterial from "@/views/pages/uiinterface/icons/icon-material.vue";
import UI_Iconpe7 from "@/views/pages/uiinterface/icons/icon-pe7.vue";
import UI_Iconsimpleline from "@/views/pages/uiinterface/icons/icon-simpleline.vue";
import UI_Iconthemify from "@/views/pages/uiinterface/icons/icon-themify.vue";
import UI_Iconweather from "@/views/pages/uiinterface/icons/icon-weather.vue";
import UI_Icontypicon from "@/views/pages/uiinterface/icons/icon-typicon.vue";
import UI_Iconflag from "@/views/pages/uiinterface/icons/icon-flag.vue";
import CompaniesGrid from '@/views/pages/crm/organisation/companies-grid.vue';
import OrganisationDetails from '@/views/pages/crm/organisation/organisation-details/organisation-details.vue';

import PersonsGrid from '@/views/pages/crm/person/persons-grid.vue'
import PersonDetails from '@/views/pages/crm/person/person-details/person-details.vue'

import DealsList from '@/views/pages/crm/deals/deals-list.vue'
import DealsKanban from '@/views/pages/crm/deals/deals-kanban.vue'
import DealsDetails from '@/views/pages/crm/deals/deals-details.vue'
// meter-opportunities
import LeadsList from '@/views/pages/crm/meter-opportunities/meter-opportunities-list.vue'
import LeadsKanban from '@/views/pages/crm/meter-opportunities/meter-opportunities-kanban.vue'
import LeadsDetails from '@/views/pages/crm/meter-opportunities/meter-opportunities-details.vue'
import MeterOppEdit from '@/views/pages/crm/meter-opportunities/meter-opportunities-edit.vue'
import MeterOppCreate from '@/views/pages/crm/meter-opportunities/create-meter-opportunity.vue'

import PipelineList from '@/views/pages/crm/pipeline/pipeline-list.vue'
import Campaign from '@/views/pages/crm/campaign/campaign-index.vue'
import CampaignComplete from '@/views/pages/crm/campaign/campaign-complete.vue'
import CampaignArchieve from '@/views/pages/crm/campaign/campaign-archieve.vue'
import ProjectsList from '@/views/pages/crm/projects/projects-list.vue'
import ProjectGrid from '@/views/pages/crm/projects/project-grid.vue'
import Tasks from '@/views/pages/crm/tasks/task-content.vue'
import TasksImportant from '@/views/pages/crm/tasks/task-important.vue'
import TasksCompleted from '@/views/pages/crm/tasks/task-completed.vue'
import Analytics from '@/views/pages/crm/analytics/analytics-index.vue'
import Activities from '@/views/pages/crm/activities/activities-list.vue'
import ActivityCalls from '@/views/pages/crm/activities/activity-calls.vue'
import ActivityMail from '@/views/pages/crm/activities/activity-mail.vue'
import ActivityTask from '@/views/pages/crm/activities/activity-task.vue'
import ActivityMeeting from '@/views/pages/crm/activities/activity-meeting.vue'
import Dashboard from '@/views/pages/dashboard/dashboard-index.vue'
import DealsDashboard from '@/views/pages/dashboard/deals-dashboard/deals-dashboard.vue'
import DealsDashboardTwo from '@/views/pages/dashboard/deals-dashboard/deals-two-dashboard.vue'
import Application from '@/views/pages/application/application-index.vue'
import Chat from '@/views/pages/application/chat/chat-index.vue'
import Call from '@/views/pages/application/call/call-index.vue'
import VideoCall from '@/views/pages/application/call/video-call.vue'
import AudioCall from '@/views/pages/application/call/audio-call.vue'
import CallHistory from '@/views/pages/application/call/call-history.vue'
import Calendar from '@/views/pages/application/calendar-index.vue'
import Email from '@/views/pages/application/email-index.vue'
import Todo from '@/views/pages/application/todo-index.vue'
import Notes from '@/views/pages/application/notes-index.vue'
import FileManager from '@/views/pages/application/files/file-manager.vue'
import FileShared from '@/views/pages/application/files/file-shared.vue'
import FileRecent from '@/views/pages/application/files/file-recent.vue'
import FileDocument from '@/views/pages/application/files/file-document.vue'
import FileFavourites from '@/views/pages/application/files/file-favourites.vue'
import FileArchived from '@/views/pages/application/files/file-archived.vue'
import FileManagerDeleted from '@/views/pages/application/files/file-manager-deleted.vue'
import LeadsDashboard from '@/views/pages/dashboard/leads-dashboard/leads-dashboard.vue';
import ProjectDashboard from '@/views/pages/dashboard/project-dashboard/project-dashboard.vue';
import ProjectDetails from '@/views/pages/crm/projects/project-details.vue'
import Proposals_List from '@/views/pages/crm/proposals/proposals-list.vue'
import Contracts_List from '@/views/pages/crm/contracts/contracts-list.vue'
import Invoices_List from '@/views/pages/crm/invoices/invoices-list.vue'
import Proposals_Grid from '@/views/pages/crm/proposals/proposals-grid.vue'
import Estimations_Kanban from '@/views/pages/crm/estimations/estimations-kanban.vue'
import Estimations_List from '@/views/pages/crm/estimations/estimations-list.vue'
import Payments_List from '@/views/pages/crm/payments/payments-list.vue'
import Invoice_Grid from '@/views/pages/crm/invoices/invoice-grid.vue'
import Contracts_Grid from '@/views/pages/crm/contracts/contracts-grid.vue'

// contracts
import Create_contract from '@/views/pages/crm/contracts/create-contract.vue'
import Edit_contract from '@/views/pages/crm/contracts/edit-contract.vue'

// Activity
import Create_activity from '@/views/pages/crm/activities/create-activity.vue'
import Edit_activity from '@/views/pages/crm/activities/edit-activity.vue'

// territory
import Territory from '@/views/pages/settings/territory/territory.vue'
import Territory_list from '@/views/pages/settings/territory/territory-list.vue'
import Create_territory from '@/views/pages/settings/territory/create-territory.vue'
import component from 'vue3-tags-input';

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/login-2',
    name: 'login-2',
    component: Login_2
  },
  {
    path: '/login-3',
    name: 'login-3',
    component: Login_3
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/register-2',
    name: 'register-2',
    component: Register_2
  },
  {
    path: '/register-3',
    name: 'register-3',
    component: Register_3
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: Forgot_Password
  },
  {
    path: '/forgot-password-2',
    name: 'forgot-password-2',
    component: Forgot_Password_2
  },
  {
    path: '/forgot-password-3',
    name: 'forgot-password-3',
    component: Forgot_Password_3
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: Reset_Password
  },
  {
    path: '/reset-password-2',
    name: 'reset-password-2',
    component: Reset_Password_2
  },
  {
    path: '/reset-password-3',
    name: 'reset-password-3',
    component: Reset_Password_3
  },
  {
    path: '/email-verification',
    name: 'email-verification',
    component: Email_Verification
  },
  {
    path: '/email-verification-2',
    name: 'email-verification-2',
    component: Email_Verification_2
  },
  {
    path: '/email-verification-3',
    name: 'email-verification-3',
    component: Email_Verification_3
  },
  {
    path: '/two-step-verification',
    name: 'two-step-verification',
    component: Two_Step_Verification
  },
  {
    path: '/two-step-verification-2',
    name: 'two-step-verification-2',
    component: Two_Step_Verification_2
  },
  {
    path: '/two-step-verification-3',
    name: 'two-step-verification-3',
    component: Two_Step_Verification_3
  },
  {
    path: '/lock-screen',
    name: 'lock-screen',
    component: Lock_Screen
  },
  {
    path: '/success',
    name: 'success',
    component: Success
  },
  {
    path: '/success-2',
    name: 'success-2',
    component: Success_2
  },
  {
    path: '/success-3',
    name: 'success-3',
    component: Success_3
  },
  {
    path: '/blank-page',
    name: 'blank-page',
    component: Blank_Page
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: Coming_Soon
  },
  {
    path: '/under-maintenance',
    name: 'under-maintenance',
    component: Under_Maintenance
  },
  {
    path: '/dashboard',
    component: Dashboard,
    children: [
      { path: '', redirect: '/dashboard/deals-dashboard' },
      { path: 'deals-dashboard', component: DealsDashboard },
      { path: 'deals-dashboard-two', component: DealsDashboardTwo },
      { path: 'leads-dashboard', component: LeadsDashboard },
      { path: 'project-dashboard', component: ProjectDashboard },
    ]
  },
  {
    path: '/quoting',
    component: Quoting,
    children: [
      { path: '', redirect: '/quoting/get-quoting' },
      { path: 'get-quoting', component: Get_quoting },
    ]
  },
  {
    path: '/application',
    component: Application,
    children: [
      {path: '', redirect: '/application/chat'},
      {path: 'chat', component: Chat },
      { path: "calendar", component: Calendar },
      { path: "email", component: Email },
      { path: "todo", component: Todo },
      { path: "notes", component: Notes },
      { path: "file-manager", component: FileManager },
      { path: "file-shared", component: FileShared },
      { path: "file-recent", component: FileRecent },
      { path: "file-document", component: FileDocument },
      { path: "file-favourites", component: FileFavourites },
      { path: "file-archived", component: FileArchived },
      { path: "file-manager-deleted", component: FileManagerDeleted },
    ]
  },
  {
    path: '/call',
    component: Call,
    children: [
      { path: '', redirect: '/call/video-call' },
      { path: "video-call", component: VideoCall },
      { path: "audio-call", component: AudioCall },
      { path: "call-history", component: CallHistory },
    ]
  },
  {
    path: '/report',
    component: Crm_Report,
    children: [
      { path: '', redirect: '/report/lead-reports' },
      { path: "lead-reports", component: Lead_Report },
      { path: "deal-reports", component: Deal_Report },
      { path: "contact-reports", component: Contact_Report },
      { path: "company-reports", component: Company_Report },
      { path: "project-reports", component: Project_Report },
      { path: "task-reports", component: Task_Report },
    ]
  },
  {
    path: '/crm-settings',
    component: Crm_Index,
    children: [
      { path: '', redirect: '/crm-settings/calls' },
      { path: "calls", component: Crm_Calls },
      { path: "industry", component: Crm_Industry },
      { path: "contact-stage", component: Contact_Stage },
      { path: "lost-reason", component: Lost_Reason },
      { path: "sources", component: Crm_Sources },
    ]
  },
  {
    path: '/membership',
    component: Crm_Membership,
    children: [
      { path: '', redirect: '/membership/membership-plans' },
      { path: "membership-plans", component: Membership_Plans },
      { path: "membership-addons", component: Membership_Addons },
      { path: "membership-transactions", component: Membership_Transactions },
    ]
  },
  {
    path: '/content',
    component: Crm_Content,
    children: [
      { path: '', redirect: '/content/pages' },
      { path: "pages", component: Crm_Pages },
      { path: "testimonials", component: Crm_Testimonials },
      { path: "faq", component: Crm_Faq },
    ]
  },
  {
    path: '/location',
    component: Crm_Location,
    children: [
      { path: '', redirect: '/location/countries' },
      { path: "countries", component: Crm_Countries },
      { path: "states", component: Crm_States },
      { path: "cities", component: Crm_Cities },
    ]
  },
  {
    path: '/error',
    component: Crm_Error,
    children: [
      { path: '', redirect: '/error/error-404' },
      { path: "error-404", component: Error_404 },
      { path: "error-500", component: Error_500 },
    ]
  },
  {
    path: '/user',
    component: Crm_User,
    children: [
      { path: '', redirect: '/user/manage-users' },
      { path: "manage-users", component: Manage_Users },
      { path: "roles-permissions", component: Roles_Permissions },
      { path: "permission", component: Crm_Permission },
      { path: "delete-request", component: Delete_Request },
    ]
  },
  {
    path: '/settings',
    component: Crm_Settings,
    children: [
      { path: '', redirect: '/settings/profile' },
      { path: "profile", component: Crm_Profile },
      { path: "security", component: Crm_Security },
      { path: "notifications", component: Crm_Notifications },
      { path: "connected-apps", component: Connected_Apps },
    ]
  },
  {
    path: '/data-import',
    component: Website_Settings,
    children: [
      { path: '', redirect: '/data-import/import-data-status' },
      { path: "upload-file", component: Upload_file },
      { path: "import-data-status", component: Data_status },
      { path: "map-data", component: Map_data },
      { path: "assign-data", component: Assign_data },
      { path: "validate", component: Validate },
      { path: "appearance", component: Appearance },
      { path: "create-mode", component: Create_mode },
      { path: "error-logs", component: Error_logs },
    ]
  },
  {
    path: '/users',
    component: Users,
    children: [
      { path: '', redirect: '/users/users/users-list' },
      { path: "users-list", component: Users_list },
      { path: "create-user", component: Create_user },
    ]
  },
  {
    path: '/territory',
    component: Territory,
    children: [
      { path: '', redirect: '/territory/territory-list' },
      { path: "territory-list", component: Territory_list },
      { path: "create-territory", component: Create_territory },
    ]
  },
  // {
  //   path: '/app-settings',
  //   component: App_Settings,
  //   children: [
  //     { path: '', redirect: '/app-settings/invoice-settings' },
  //     { path: "invoice-settings", component: Invoice_Settings },
  //     { path: "printers", component: Printers },
  //     { path: "custom-fields", component: Custom_Fields },
  //   ]
  // },
  // {
  //   path: '/system-settings',
  //   component: System_Settings,
  //   children: [
  //     { path: '', redirect: '/system-settings/email-settings' },
  //     { path: "email-settings", component: Email_Settings },
  //     { path: "sms-gateways", component: Sms_Gateways },
  //     { path: "gdpr-cookies", component: Gdpr_Cookies },
  //   ]
  // },
  // {
  //   path: '/financial-settings',
  //   component: Financial_Settings,
  //   children: [
  //     { path: '', redirect: '/financial-settings/payment-gateways' },
  //     { path: "payment-gateways", component: Payment_Gateways },
  //     { path: "bank-accounts", component: Bank_Accounts },
  //     { path: "tax-rates", component: Tax_Rates },
  //     { path: "currencies", component: Currencies },
  //   ]
  // },
  // {
  //   path: '/other-settings',
  //   component: Other_Settings,
  //   children: [
  //     { path: '', redirect: '/other-settings/storage' },
  //     { path: "storage", component: Storage_Settings },
  //     { path: "ban-ip-address", component: Ban_Ip_Address },
  //   ]
  // },
  {
    path: '/support',
    component: Crm_Support,
    children: [
      { path: '', redirect: '/support/contact-messages' },
      { path: "contact-messages", component: Contact_Messages },
      { path: "tickets", component: Crm_Tickets },
    ]
  },
  {
    path: '/icons',
    component: Icons,
    children: [
      { path: '', redirect: '/icons/icon-fontawesome' },
      { path: "icon-fontawesome", component: UI_Iconfontawesome },
      { path: "icon-feather", component: UI_Iconfeather },
      { path: "icon-ionic", component: UI_Iconionic },
      { path: "icon-material", component: UI_Iconmaterial },
      { path: "icon-pe7", component: UI_Iconpe7 },
      { path: "icon-simpleline", component: UI_Iconsimpleline },
      { path: "icon-themify", component: UI_Iconthemify },
      { path: "icon-weather", component: UI_Iconweather },
      { path: "icon-typicon", component: UI_Icontypicon },
      { path: "icon-flag", component: UI_Iconflag },
    ]
  },
  {
    path: '/baseui',
    component: BaseUi,
    children: [
      { path: '', redirect: '/baseui/ui-alerts' },
      { path: "ui-alerts", component: UI_Alerts },
      { path: "ui-accordion", component: UI_Accordion },
      { path: "ui-avatar", component: UI_Avatar },
      { path: "ui-badges", component: UI_Badges },
      { path: "ui-borders", component: UI_Borders },
      { path: "ui-buttons", component: UI_Buttons },
      { path: "ui-buttons-group", component: UI_Buttons_group },
      { path: "ui-breadcrumb", component: UI_Breadcrumb },
      { path: "ui-cards", component: UI_Cards },
      { path: "ui-carousel", component: UI_Carousel },
      { path: "ui-colors", component: UI_Colors },
      { path: "ui-dropdowns", component: UI_Dropdowns },
      { path: "ui-grid", component: UI_Grid },
      { path: "ui-images", component: UI_Images },
      { path: "ui-lightbox", component: UI_Lightbox },
      { path: "ui-media", component: UI_Media },
      { path: "ui-modals", component: UI_Modals },
      { path: "ui-offcanvas", component: UI_Offcanvas },
      { path: "ui-pagination", component: UI_Pagination },
      { path: "ui-popovers", component: UI_Popovers },
      { path: "ui-progress", component: UI_Progress },
      { path: "ui-placeholders", component: UI_Placeholders },
      { path: "ui-rangeslider", component: UI_Rangeslider },
      { path: "ui-spinner", component: UI_Spinner },
      { path: "ui-sweetalerts", component: UI_Sweetalerts },
      { path: "ui-nav-tabs", component: UI_Nav_Tabs },
      { path: "ui-tooltips", component: UI_Tooltips },
      { path: "ui-typography", component: UI_Typography },
      { path: "ui-video", component: UI_Video },
    ]
  },
  {
    path: '/advancedui',
    component: Advancedui,
    children: [
      { path: '', redirect: '/advancedui/ui-ribbon' },
      { path: "ui-ribbon", component: UI_Ribbon },
      { path: "ui-clipboard", component: UI_Clipboard },
      { path: "ui-drag-drop", component: UI_Drag_Drop },
      { path: "ui-text-editor", component: UI_Text_Editor },
      { path: "ui-counter", component: UI_Counter },
      { path: "ui-scrollbar", component: UI_Scrollbar },
      { path: "ui-rating", component: UI_Rating },
      { path: "ui-stickynote", component: UI_Stickynote },
      { path: "ui-timeline", component: UI_Timeline }
    ]
  },
  {
    path: '/tables',
    component: Tables,
    children: [
      { path: '', redirect: '/tables/data-tables' },
      { path: "data-tables", component: Data_Tables },
      { path: "tables-basic", component: Tables_Basic }
    ]
  },
  {
    path: '/charts',
    component: Charts,
    children: [
      { path: '', redirect: '/charts/chart-apex' },
      { path: "chart-apex", component: Chartapex },
      { path: "chart-c3", component: Chartc3 },
      { path: "chart-flot", component: Chartflot },
      { path: "chart-js", component: Chartjs },
      { path: "chart-morris", component: Chartmorris }
    ]
  },
  {
    path: '/forms',
    component: Forms,
    children: [
      { path: '', redirect: '/forms/form-basic-inputs' },
      { path: "form-basic-inputs", component: Formbasicinput },
      { path: "form-checkbox-radios", component: Formcheckboxradios },
      { path: "form-grid-gutters", component: Formgridgutters },
      { path: "form-input-groups", component: FormInput },
      { path: "form-select", component: Formselect },
      { path: "form-mask", component: Formmask },
      { path: "form-fileupload", component: Formfileupload },
      { path: "form-horizontal", component: FormHorizontal },
      { path: "form-vertical", component: Formvertical },
      { path: "form-floating-labels", component: Formfloatinglabels },
      { path: "form-validation", component: Formvalidation },
      { path: "form-select2", component: Formselect2 },
      { path: "form-wizard", component: Formwizard },
    ]
  },
  {
    path: '/crm',
    component: CRM,
    children: [
      { path: '', redirect: '/crm/contacts-list' },
      { path: 'contacts-list', component: ContactsList },
      { path: 'contact-grid', component: ContactGrid },
      { path: 'contact-details', component: ContactDetails },

      // organisation
      { path: 'organisation-list', component: CompaniesList },
      { path: 'create-organisation', component: CreateOrganisation },
      { path: 'companies-grid', component: CompaniesGrid },
      { path: 'organisation-details', component: OrganisationDetails },

      // person
      { path: 'person-list', component: PersonsList },
      { path: 'create-person', component: CreatePerson },
      { path: 'persons-grid' , component: PersonsGrid},
      { path: 'person-details' , component: PersonDetails},


      { path: 'deals-list', component: DealsList },
      { path: 'deals-kanban', component: DealsKanban },
      { path: 'deals-details', component: DealsDetails },

      // meter opportuities
      { path: 'meter-opportunities-list', component: LeadsList },
      { path: 'meter-opportunities-kanban', component: LeadsKanban },
      { path: 'meter-opportunities-details', component: LeadsDetails },
      { path: 'meter-opportunities-edit', component: MeterOppEdit },
      { path: 'create-meter-opportunity', component: MeterOppCreate },
      

      
      { path: 'pipeline-list', component: PipelineList },
      { path: 'campaign', component: Campaign },
      { path: 'campaign-complete', component: CampaignComplete },
      { path: 'campaign-archieve', component: CampaignArchieve },
      { path: 'projects-list', component: ProjectsList },
      { path: 'project-grid', component: ProjectGrid },
      { path: 'tasks', component: Tasks },
      { path: 'tasks-important', component: TasksImportant },
      { path: 'tasks-completed', component: TasksCompleted },
      { path: 'analytics', component: Analytics },

      // activity
      { path: 'activities-list', component: Activities },
      { path: 'activity-calls', component: ActivityCalls },
      { path: 'activity-mail', component: ActivityMail },
      { path: 'activity-task', component: ActivityTask },
      { path: 'activity-meeting', component: ActivityMeeting },
      { path: 'create-activity', component: Create_activity },
      { path: 'edit-activity', component: Edit_activity },


      { path: 'project-details', component: ProjectDetails },
      { path: 'estimations-kanban', component: Estimations_Kanban },
      { path: 'estimations-list', component: Estimations_List },
      { path: 'payments-list', component: Payments_List },
      { path: 'proposals-list', component: Proposals_List },
      { path: 'invoices-list', component: Invoices_List },

    // contracts
      { path: 'create-contract', component: Create_contract },
      { path: 'contracts-list', component: Contracts_List },
      { path: 'edit-contract', component: Edit_contract},
      { path: 'proposals-grid', component: Proposals_Grid },
      { path: 'invoice-grid', component: Invoice_Grid },
      { path: 'contracts-grid', component: Contracts_Grid },
    ]
  }
];

export const router = createRouter({
  history: createWebHistory('/'),
  linkActiveClass: 'active',
  routes,
});

// Global Navigation Guard
router.beforeEach((to, from, next) => {
  // Scroll to the top of the page on every route change
  window.scrollTo({ top: 0, behavior: 'smooth' });
  
  // Example: Ensure authentication for protected routes
  const token = localStorage.getItem('authToken');
  
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    // If the route requires authentication and there's no token, redirect to login
    next('/');
  } else {
    next(); // Proceed with the navigation
  }
});

export default router;