<template>
  <div class="col-12 theiaStickySidebar">
    <!-- Settings Sidebar -->
    <!-- <div class="card mb-0"> -->
      <!-- <div class="card-body"> -->
        <div class="settings-sidebar">
          <h4>Data import</h4>
          <div class="row">
          <div class="col-2"></div>
            <div class="col text-center p-2">
              <router-link to="/data-import/import-data-status">Status</router-link>
            </div>
            <div class="col text-center p-2">
              <router-link to="/data-import/upload-file"
                >Upload File</router-link
              >
            </div>
            <div class="col text-center p-2">
              <router-link to="/data-import/validate">Validate</router-link>
            </div>
            <div class="col text-center p-2">
              <router-link to="/data-import/map-data">Map Data</router-link>
              
            </div>
            <div class="col text-center p-2">
              <router-link to="/data-import/assign-data">Assign Data</router-link>
              
            </div>
            <div class="col text-center p-2">
              <router-link to="/data-import/create-mode">Create Mode</router-link>
            </div>
            <div class="col-2"></div>
          </div>
        <!-- </div> -->
      <!-- </div> -->
    </div>
    <!-- /Settings Sidebar -->
  </div>
</template>
<style scoped lang="scss">
 .settings-sidebar{
  .col:has(.active){
    border-bottom: 4px solid #E41F07;
    .active{
      color: #E41F07;
    }
  }
 }
</style>