<template>
<layouts-header></layouts-header>
<layouts-sidebar></layouts-sidebar>

<!-- Page Wrapper -->
<div class="page-wrapper">
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <!-- Page Header -->
        <page-header :title="title" />
        <!-- /Page Header -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <!-- Contact User -->
        <div class="contact-head">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <ul class="contact-breadcrumb">
                <li>
                  <router-link to="/crm/organisation-list"><i class="ti ti-arrow-narrow-left"></i>Organisations</router-link>
                </li>
                <li>{{ orgData.name }}</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="contact-wrap">
          <div class="contact-profile">
            <div class="avatar company-avatar">
              <img v-if="imagePreview" :src="imagePreview" class="img-fluid company_img" />
              <img v-else src="@/assets/img/icons/company-icon-01.svg" alt="Image" />
            </div>
            <div class="name-user">
              <h5><router-link :to="{ path: '/crm/create-organisation', query: { orgId: orgId }}">{{ orgData.name }}</router-link></h5>
              <p class="mb-0" title="Postcode">{{ orgData.postcode }}</p>
              <p title="Address" class="mb-1"><i class="ti ti-map-pin-pin me-1"></i>{{ orgData.territory_name }}, {{ orgData.country_name }}</p>
            </div>
          </div>
        </div>
        <!-- /Contact User -->
      </div>

      <!-- Contact Sidebar -->
      <div class="col-xl-3 theiaStickySidebar">
        <div class="contact-sidebar">
          <h6>Basic Information</h6>
          <ul class="basic-info">
            <li>
              <!-- <div class="row"> -->
              <div class="col-4">
                <h6 class="mb-0">Territory :</h6>
              </div>
              <div class="col-8">
                <p class="mb-0">{{ orgData.territory_name }}</p>
              </div>
              <!-- </div> -->
            </li>
            <li>
              <div class="col-4">
                <h6 class="mb-0">Industry :</h6>
              </div>
              <div class="col-8">
                <p class="mb-0">{{ orgData.industry }}</p>
              </div>
            </li>
            <li>
              <div class="col-4">
                <h6 class="mb-0">Responsible :</h6>
              </div>
              <div class="col-8">
                <p class="mb-0">{{ orgData.responsible_name }}</p>
              </div>
            </li>
          </ul>
          <h6>Other Information</h6>
          <ul class="other-info">
            <li>
              <div class="col-4">
                <p class="mb-0 ">Phone :</p>
              </div>
              <div class="col-8">
                <p class="mb-0">{{ orgData.telephone }}</p>
              </div>
            </li>
            <li>
              <div class="col-4">
                <p class="mb-0 ">Vat Group :</p>
              </div>
              <div class="col-8">
                <p class="mb-0">{{ orgData.vat_group }}</p>
              </div>
            </li>
            <li>
              <div class="col-4">
                <p class="mb-0 ">Phone :</p>
              </div>
              <div class="col-8">
                <p class="mb-0">{{ orgData.telephone }}</p>
              </div>
            </li>
            <li>
              <div class="col-4">
                <p class="mb-0 ">Status :</p>
              </div>
              <div class="col-8">
                <p class="mb-0">{{ orgData.status }}</p>
              </div>
            </li>
            <li>
              <div class="col-4">
                <p class="mb-0 ">Type :</p>
              </div>
              <div class="col-8">
                <p class="mb-0">{{ orgData.type }}</p>
              </div>
            </li>
            <li>
              <div class="col-4">
                <p class="mb-0 ">Employees :</p>
              </div>
              <div class="col-8">
                <p class="mb-0">{{ orgData.no_of_employees }}</p>
              </div>
            </li>
            <li>
              <div class="col-4">
                <p class="mb-0 ">Fax :</p>
              </div>
              <div class="col-8">
                <p class="mb-0">{{ orgData.fax }}</p>
              </div>
            </li>
            <li>
              <div class="col-4">
                <p class="mb-0 ">www :</p>
              </div>
              <div class="col-8">
                <p class="mb-0">{{ orgData.www }}</p>
              </div>
            </li>
            <li>
              <div class="col-4">
                <p class="mb-0 ">Address :</p>
              </div>
              <div class="col-8">
                <p class="mb-0">{{ orgData.address }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /Contact Sidebar -->

      <!-- Contact Details -->
      <div class="col-xl-9">
        <div class="contact-tab-wrap">
          <ul class="contact-nav nav">
            <li>
              <a href="javascript:;" data-bs-toggle="tab" data-bs-target="#opportunities" class="active"><i class="ti ti-chart-arcs"></i>Meter Opportunities</a>
            </li>
            <li>
              <a href="javascript:;" data-bs-toggle="tab" data-bs-target="#contracts"><i class="ti ti-file-check"></i>Contracts</a>
            </li>
            <li>
              <a href="javascript:;" data-bs-toggle="tab" data-bs-target="#activities"><i class="ti ti-bounce-right"></i>Activities</a>
            </li>
            <li>
              <a href="javascript:;" data-bs-toggle="tab" data-bs-target="#persons"><i class="ion-person"></i>Persons</a>
            </li>
          </ul>
        </div>

        <!-- Tab Content -->
        <div class="contact-tab-view">
          <div class="tab-content pt-0">
            <!-- opportunities -->
            <div class="tab-pane active show" id="opportunities">
              <organisation-opportunities></organisation-opportunities>
            </div>

            <!-- Activities -->
            <div class="tab-pane fade" id="activities">
              <organisation-activities></organisation-activities>
            </div>

            <!-- Persons -->
            <div class="tab-pane fade" id="persons">
                <organisation-persons></organisation-persons>
            </div>

            <!-- contracts -->
            <div class="tab-pane fade" id="contracts">
                <organisation-contract></organisation-contract>
            </div>

          </div>
        </div>
        <!-- /Tab Content -->
      </div>
      <!-- /Contact Details -->
    </div>
  </div>
</div>
<!-- /Page Wrapper -->

</template>

<script>
import {
  ref,
  onMounted,
  watchEffect
} from 'vue';
import {
  useStore
} from "vuex";
import {
  useRoute,
  useRouter
} from 'vue-router';
export default {
  data() {
    return {
      title: "Organisation Overview",
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const orgData = ref({});
    const orgId = ref(null);
    const imagePreview = ref('')

    const fetchOrganisations = async (orgId) => {
      const response = await store.dispatch('organisation/fetchOrgData', orgId);
      if (response) {
        orgData.value = response;
        imagePreview.value = response.logo;
      }
    };
    onMounted(() => {
      orgId.value = route.query.orgId;
      if (orgId.value) {
        fetchOrganisations(orgId.value)
      }
    })

    return {
      orgData,
      fetchOrganisations,
      orgId,
      imagePreview
    };
  },
};
</script>
<style>
 .company_img{
  height: 80px !important;
  width: 80px !important;
  object-fit: cover;
 }
</style>