<template>
<div>
  <div class="row tab-row-data m-0">
    <div class="col-md-2">
      <h5 class="bg-teal p-2 mb-3 rounded text-center">Basic details</h5>
      <div class="row">
        <div class="col-12">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Postcode
              <i class="ti ti-search" style="cursor: pointer" @click="openModal(formData.quoteDetails.PostCode, 'postcode')"></i></label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.PostCode" />
            <span class="text-danger" v-if="isNotValid && (formData.quoteDetails.PostCode === '')">Please enter the postcode</span>
          </div>
        </div>
        <div class="col-12">
          <div class="form-wrap mb-2">
            <label class="col-form-label">MPAN / MPR NO.
              <i class="ti ti-search" style="cursor: pointer" @click="
                    openModal(formData.quoteDetails.GasSupply.MPR, 'mprn')
                  "></i></label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.GasSupply.MPR" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h5 class="bg-teal p-2 mb-3 rounded text-center">Usage details</h5>
      <div class="row">
        <div class="col-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Annual Usage (kWh)</label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.GasSupply.Consumption.Amount" />
            <span class="text-danger" v-if="isNotValid && (formData.quoteDetails.GasSupply.Consumption.Amount === 0 )">Please enter the annual usage</span>
          </div>
        </div>
        <div class="col-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Old Rate (pence/kWh)</label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.OldRate" @blur="calculateCurrentCost" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Standing Charge (pence/day)</label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.StandingCharge" @blur="calculateCurrentCost" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Current Cost (£)</label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.CurrentCost" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h5 class="bg-teal p-2 mb-3 rounded text-center">Contract details</h5>
      <div class="row">
        <div class="col-md-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Payment Type</label>
            <Field as="select" name="new_supplier_id" class="form-select" v-model="formData.quoteDetails.PaymentMethod">
              <option v-for="res in dropdownOptions.contract_payment_type" :key="res.value" :value="res.text">
                {{ res.text }}
              </option>
            </Field>
          </div>
        </div>
        <div class="col-md-6" ref="dropdownContainer">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Current Supplier</label>
            <Field name="organisation_id" type="text" class="form-control" placeholder="Search organisation name / postcode" v-model="searchQuery" @keydown.enter="searchSupplier" @input="onInput" @focus="showDropdown" />
            <ul v-if="filteredSuppliers.length" class="dropdown-menu supplier-data shadow show pe-0">
              <li class="dropdown-listing">
                <ul>
                  <li v-for="res in filteredSuppliers" :key="res.name" @click="selectSupplier(res)" class="dropdown-item" style="cursor: pointer">
                    {{ res.name }}
                  </li>
                </ul>
              </li>
            </ul>
            <span class="text-danger" v-if="isNotValid && (formData.quoteDetails.CurrentSupplier === '')">Current supplier is required</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Renewal Date </label>
            <div class="icon-form">
              <date-picker placeholder="" class="datetimepicker form-control" :editable="true" :clearable="false" :input-format="dateFormat" v-model="formData.quoteDetails.GasSupply.ContractRenewalDate" />
              <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
            </div>
            <span class="text-danger" v-if="isNotValid && (formData.quoteDetails.GasSupply.ContractRenewalDate === '')">Renewal date is required</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Unit Rate Uplift</label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.Uplift" />
            <!-- <Field as="select" name="supplier_id" class="form-select">
              <option value="">-----Select-----</option>
              <option v-for="res in dropdownOptions.contract_unit_rate_uplift" :key="res.value" :value="res.value">
                {{ res.text }}
              </option>
            </Field> -->
            <span class="text-danger" v-if="isNotValid && (formData.quoteDetails.Uplift === null) ">Unit Rate Uplift is required</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Email</label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.Email" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <h5 class="bg-teal p-2 mb-3 rounded text-center">Additional details</h5>
      <div class="row">
        <div class="col-12">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Credit Score</label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.Settings[1].value" />
            <span class="text-danger" v-if="isNotValid && (formData.quoteDetails.Settings[1].value === '0')">Credit Score is required</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<look-up-modal :isVisible="isModalVisible" :searchPostcode="selectedPostcode" :selectedLabel="selectedLabel" @close="closeModal"></look-up-modal>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  computed
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRouter,
  useRoute
} from "vue-router";
import {
  Form,
  Field,
  ErrorMessage
} from "vee-validate";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    isNotValid: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dateFormat: "yyyy-MM-dd",
    };
  },
  setup(props, {
    emit
  }) {
    const store = useStore();
    const filteredSuppliers = ref([]);
    const searchQuery = ref("");
    const dropdownContainer = ref(null);
    const selectedPostcode = ref("");
    const selectedLabel = ref("");
    const isModalVisible = ref(false);

    const supplierOptions = computed(() => store.getters["price/getSuppliers"]);
    const dropdownOptions = computed(() => store.getters["price/getDropdowns"]);

    const formData = ref({
      mainProductService: "gas",
      quoteDetails: {
        PostCode: "",
        Uplift: null,
        PaymentMethod: "Monthly Direct Debit",
        CurrentSupplier: "",
        OldRate: "0.00",
        StandingCharge: "0.00",
        CurrentCost: "0.00",
        EnableQuoteEmail: false,
        QuoteReference: "",
        Email: "",
        BusinessType: "LTD",
        GasSupply: {
          Consumption: {
            Amount: 0.00,
            Type: "Day",
          },
          ContractRenewalDate: "",
          MPR: "",
          NewContractEndDate: "",
          SmartMeter: "false",
        },
        Settings: [{
            key: "upliftBehaviour",
            value: "closest"
          },
          {
            key: "CreditScore",
            value: "0"
          },
          {
            key: "ShowNoQuoteReason",
            value: "True"
          }
        ],
      },
    });

    // Function to calculate CurrentCost on blur
    const calculateCurrentCost = () => {
      // old rate annual price calculation
      const annualPrice = parseFloat(formData.value.quoteDetails.GasSupply.Consumption.Amount) || 0;
      const oldRate = parseFloat(formData.value.quoteDetails.OldRate) || 0;
      const annual_rate = (annualPrice * oldRate) / 100 ;

      // old rate annual price calculation
      const standingCharge = parseFloat(formData.value.quoteDetails.StandingCharge) || 0;
      const statngCalc = (standingCharge * 365 ) / 100 ;

      // calculate current rate
      // if(annual_rate !== 0 && statngCalc !== 0) {
        formData.value.quoteDetails.CurrentCost = (annual_rate + statngCalc).toFixed(2);
      // } else {
        // formData.value.quoteDetails.CurrentCost = 0.00;
      // }
    };

    const onInput = () => {
      searchSupplier();
    };

    const searchSupplier = () => {
      const query = searchQuery.value.toLowerCase();
      if (query) {
        filteredSuppliers.value = supplierOptions.value.filter((org) => {
          const nameMatch = org.name.toLowerCase().includes(query);
          return nameMatch;
        });
      } else {
        // If the query is empty, hide the dropdown
        filteredSuppliers.value = [];
      }
    };

    const showDropdown = () => {
      // Show all organisations when the input is focused (clicked)
      if (!searchQuery.value) {
        filteredSuppliers.value = supplierOptions.value;
      }
    };

    const selectSupplier = (organisation) => {
      formData.value.quoteDetails.CurrentSupplier = organisation.name;
      searchQuery.value = organisation.name;

      filteredSuppliers.value = [];
    };

    const openModal = (code, label) => {
      selectedPostcode.value = code; // Assign the clicked value to selectedPostcode
      selectedLabel.value = label;
      isModalVisible.value = true;
    };

    const closeModal = () => {
      isModalVisible.value = false;
    };
    const hideDropdown = () => {
      filteredSuppliers.value = [];
    };

    const handleClickOutside = (event) => {
      // Check if the click happened outside the dropdown container
      if (
        dropdownContainer.value &&
        !dropdownContainer.value.contains(event.target)
      ) {
        hideDropdown();
      }
    };
    const emitFormData = async () => {
      emit("submit-form", formData.value); // If valid, emit form data
    };

    onMounted(async () => {
      emitFormData();
      document.addEventListener("click", handleClickOutside);
      store.dispatch("price/fetchSuppliers");
    });
    return {
      calculateCurrentCost,
      emitFormData,
      selectedLabel,
      selectedPostcode,
      openModal,
      closeModal,
      isModalVisible,
      filteredSuppliers,
      dropdownContainer,
      handleClickOutside,
      hideDropdown,
      showDropdown,
      searchSupplier,
      searchQuery,
      selectSupplier,
      onInput,
      formData,
      supplierOptions,
      dropdownOptions,
    };
  },
};
</script>

<style scoped>
.tab-row-data {
  /* overflow-x: auto; */
  flex-wrap: inherit;
}

.supplier-data {
  width: 245px;
  max-height: 350px;
  overflow: auto;
}

.col-form-label {
  font-size: 12px;
}

.form-control {
  font-size: 12px;
}

.not-valid {
  border-color: #FC0027;
}
</style>
