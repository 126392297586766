<template>
<div class="modal fade" :class="{ show: isVisible }" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: block" aria-modal="true" role="dialog" v-if="isVisible">
  <div class="modal-dialog modal-xl modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header border-0 m-0 justify-content-end">
        <button class="btn-close" @click="closeModal">
          <i class="ti ti-x"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-3">
              <div class="form-wrap">
                <label class="col-form-label">Lookup Type</label>
                <Field as="select" name="lookup_type" class="form-select" v-model="selectedLookupType" @change="updateSearchBy">
                  <option value="">-----Select-----</option>
                  <option v-for="res in lookupTypes" :key="res.value" :value="res.value">
                    {{ res.text }}
                  </option>
                </Field>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-wrap">
                <label class="col-form-label">Search By</label>
                <Field as="select" name="search_by" class="form-select" v-model="selectedSearchBy">
                  <option value="">-----Select-----</option>
                  <option v-for="option in filteredSearchBy" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </Field>
              </div>
            </div>
            <div class="col-md-3" v-if="selectedSearchBy === 'company_name_postcode'">
              <div class="form-wrap">
                <label class="col-form-label">Name</label>
                <Field as="input" name="company_name" class="form-control" v-model="companyName" />
              </div>
            </div>
            <div class="col-md-3" v-if="selectedSearchBy === 'company_name_postcode'">
              <div class="form-wrap">
                <label class="col-form-label">Postcode</label>
                {{ localPostcode }}
                <Field as="input" name="postcode" class="form-control" v-model="localPostcode" />
              </div>
            </div>
            <div class="col-md-3" v-if="selectedSearchBy !== 'company_name_postcode'">
              <div class="form-wrap">
                <label class="col-form-label">{{ dynamicLabel }}</label>
                <Field as="input" name="postcode" class="form-control" v-model="localPostcode" />
              </div>
            </div>
          </div>
          <div class="row mt-3 justify-content-center">
            <div class="col-md-6"></div>
            <div class="col-md-3 text-end">
              <button class="btn btn-primary" @click="handleSearch(selectedLabel)">
                Search
              </button>
            </div>
          </div>
          <div class="row mt-3 border-top pt-3" v-if="!searchCompany && gaspostcodeSearchResults.length > 0">
            <div class="col-md-12">
              <div class="table-responsive table_height">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" class="bg-teal text-white">
                        Full Address
                      </th>
                      <th scope="col" class="bg-teal text-white text-center">
                        Electric Meters
                      </th>
                      <th scope="col" class="bg-teal text-white text-center">
                        Gas Meters
                      </th>
                      <th scope="col" class="bg-teal text-white"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="res in gaspostcodeSearchResults" :key="res">
                      <td>{{ res.fullAddress }}</td>
                      <td class="text-center">1</td>
                      <td class="text-center">1</td>
                      <td>
                        <button class="btn btn-teal text-end" @click="getCompanyDetails(res.propertyAddressId)">
                          Look Up
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row mt-3 border-top pt-3 justify-content-center align-items-center" v-if="searchCompany">
            <div class="col-md-3">
              <button class="btn btn-primary" @click="backSearch">
                <i class="ti ti-arrow-narrow-left"></i>
              </button>
            </div>
            <div class="col-md-9">
              <h5>{{ electricInfos[0].fullAddress }}</h5>
            </div>
            <div class="col-md-12">
              <div class="contact-tab-wrap mb-0 px-2">
                <ul class="contact-nav nav">
                  <li class="col-md-2 me-0">
                    <a href="javascript:;" data-bs-toggle="tab" data-bs-target="#gasInfo" class="active">Gas</a>
                  </li>
                  <li class="col-md-2 me-0">
                    <a href="javascript:;" data-bs-toggle="tab" data-bs-target="#electricityInfo">Electricity</a>
                  </li>
                </ul>
              </div>

              <!-- Tab Content -->
              <div class="contact-tab-view mb-0 p-2">
                <div class="tab-content pt-0">
                  <!-- gas -->
                  <div class="tab-pane active show" id="gasInfo">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" class="bg-teal text-white">MPR</th>
                          <th scope="col" class="bg-teal text-white text-center">
                            Serial Number
                          </th>
                          <th scope="col" class="bg-teal text-white text-center">
                            IGT
                          </th>
                          <th scope="col" class="bg-teal text-white text-center">
                            Region
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="gasInfos.length > 0">
                          <td>{{ gasInfos[0].mprn }}</td>
                          <td class="text-center">
                            {{ gasInfos[0].meterSerialNumber }}
                          </td>
                          <td class="text-center"></td>
                          <td class="text-center">
                            {{ gasInfos[0].address[0].town }}
                          </td>
                        </tr>
                        <tr v-else>
                          <td colspan="4" class="text-center">
                            No Data Found
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- electricity -->
                  <div class="tab-pane fade table-responsive" id="electricityInfo">
                    <table class="table table-hover table-responsive table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" class="bg-teal text-white">
                            MPAN Top
                          </th>
                          <th scope="col" class="bg-teal text-white text-center">
                            MPAN Bottom
                          </th>
                          <th scope="col" class="bg-teal text-white text-center">
                            Serial Number
                          </th>
                          <th scope="col" class="bg-teal text-white">
                            Measurement Class
                          </th>
                          <th scope="col" class="bg-teal text-white">AMR</th>
                          <th scope="col" class="bg-teal text-white text-center">
                            Meter Type
                          </th>
                          <th scope="col" class="bg-teal text-white text-center">
                            SSC
                          </th>
                          <th scope="col" class="bg-teal text-white">
                            De-Energised
                          </th>
                          <th scope="col" class="bg-teal text-white">
                            Related
                          </th>
                          <th scope="col" class="bg-teal text-white text-center">
                            Key Meter
                          </th>
                          <th scope="col" class="bg-teal text-white text-center">
                            Domestic
                          </th>
                          <th scope="col" class="bg-teal text-white">
                            Region
                          </th>
                          <th scope="col" class="bg-teal text-white text-center">
                            GSP
                          </th>
                          <th scope="col" class="bg-teal text-white">
                            Green Electric Deal
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="electricInfos.length > 0">
                          <td class="text-center"></td>
                          <td>{{ electricInfos[0].mpan }}</td>
                          <td class="text-center"></td>
                          <td></td>
                          <td></td>
                          <td class="text-center">
                            {{ electricInfos[0].meters[0].meterType }}
                          </td>
                          <td class="text-center">
                            {{ electricInfos[0].ssc }}
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr v-else>
                          <td colspan="4" class="text-center">
                            No Data Found
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /Tab Content -->
            </div>
          </div>
          <div class="row mt-3 border-top pt-3" v-if="Object.keys(gasMprnData).length > 0">
            <div class="col-md-12 mb-3">
              <div class="contact-tab-wrap mb-0 px-2">
                <ul class="contact-nav nav justify-content-center">
                  <li class="col-md-2 me-0">
                    <a href="javascript:;" class="active">Gas</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 table-responsive">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col" class="bg-teal text-white">MPR</th>
                    <th scope="col" class="bg-teal text-white text-center">
                      Serial Number
                    </th>
                    <th scope="col" class="bg-teal text-white text-center">
                      IGT
                    </th>
                    <th scope="col" class="bg-teal text-white text-center">
                      Region
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ gasMprnData.mprn }}</td>
                    <td class="text-center">
                      {{ gasMprnData.meterSerialNumber }}
                    </td>
                    <td class="text-center"></td>
                    <td class="text-center">
                      {{ gasMprnData.address[0].town }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row mt-3 border-top pt-3" v-if="Object.keys(electricData).length > 0">
            <div class="col-md-12 mb-3">
              <h5 class="text-center">{{ electricData.fullAddress }}</h5>
            </div>
            <div class="col-md-12 mb-3">
              <div class="contact-tab-wrap mb-0 px-2">
                <ul class="contact-nav nav justify-content-center">
                  <li class="col-md-2 me-0">
                    <a href="javascript:;" class="active">Electricity</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="table-responsive p-0">
              <table class="table table-hover table-responsive table-bordered">
                <thead>
                  <tr>
                    <th scope="col" class="bg-teal text-white">MPAN Top</th>
                    <th scope="col" class="bg-teal text-white text-center">
                      MPAN Bottom
                    </th>
                    <th scope="col" class="bg-teal text-white text-center">
                      Serial Number
                    </th>
                    <th scope="col" class="bg-teal text-white">
                      Measurement Class
                    </th>
                    <th scope="col" class="bg-teal text-white">AMR</th>
                    <th scope="col" class="bg-teal text-white text-center">
                      Meter Type
                    </th>
                    <th scope="col" class="bg-teal text-white text-center">
                      SSC
                    </th>
                    <th scope="col" class="bg-teal text-white">
                      De-Energised
                    </th>
                    <th scope="col" class="bg-teal text-white">Related</th>
                    <th scope="col" class="bg-teal text-white text-center">
                      Key Meter
                    </th>
                    <th scope="col" class="bg-teal text-white text-center">
                      Domestic
                    </th>
                    <th scope="col" class="bg-teal text-white">Region</th>
                    <th scope="col" class="bg-teal text-white text-center">
                      GSP
                    </th>
                    <th scope="col" class="bg-teal text-white">
                      Green Electric Deal
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center"></td>
                    <td>{{ electricData.mpan }}</td>
                    <td class="text-center"></td>
                    <td></td>
                    <td></td>
                    <td class="text-center">
                      {{ electricData.meters[0].meterType }}
                    </td>
                    <td class="text-center">{{ electricData.ssc }}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  computed,
  defineEmits
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRouter,
  useRoute
} from "vue-router";
import {
  Form,
  Field,
  ErrorMessage
} from "vee-validate";

export default {
  props: {
    isVisible: {
      type: Boolean,
    },
    searchPostcode: {
      type: String,
      default: "",
    },
    selectedLabel: {
      type: String,
      default: "postcode",
    },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup(props, {
    emit
  }) {
    const store = useStore();

    const lookupTypes = ref([{
        value: "site_lookup",
        text: "Site Lookup",
      },
      {
        value: "company_lookup",
        text: "Company Lookup",
      },
    ]);

    // Search options based on lookup type
    const searchOptions = {
      company_lookup: [{
          value: "company_name",
          text: "Company Name",
        },
        {
          value: "company_number",
          text: "Company Number",
        },
        {
          value: "company_name_postcode",
          text: "Company Name and Postcode",
        },
      ],
      site_lookup: [{
          value: "postcode",
          text: "Postcode",
        },
        {
          value: "mpan",
          text: "MPAN bottom line",
        },
        {
          value: "mprn",
          text: "MPRN",
        },
        // Add more options as needed for site lookup
      ],
    };
    const searchCompany = ref(false);
    const localPostcode = ref(props.searchPostcode);
    const selectedLookupType = ref(lookupTypes.value[0].value); // Default to the first lookup type
    const selectedSearchBy = ref(""); // Start empty, will be updated based on the lookup type
    const electricInfos = ref([]);
    const gasInfos = ref([]);
    const gaspostcodeSearchResults = ref([]);
    const gasMprnData = ref([]);
    const electricData = ref([]);
    // Function to update search by selection based on the selected lookup type
    const updateSearchBy = () => {
      const options = searchOptions[selectedLookupType.value] || [];
      selectedSearchBy.value = options.length > 0 ? options[0].value : ""; // Select the first search option by default
    };

    // Watch for changes in selectedLookupType to update selectedSearchBy
    watch(selectedLookupType, (newValue) => {
      updateSearchBy(); // Update search options when lookup type changes
    });

    // Computed property to filter search options based on selected lookup type
    const filteredSearchBy = computed(() => {
      return searchOptions[selectedLookupType.value] || [];
    });

    // Computed property to determine the dynamic label based on selected search type
    const dynamicLabel = computed(() => {
      if (selectedSearchBy.value === "company_name") {
        return "Company Name";
      } else if (selectedSearchBy.value === "company_number") {
        return "Company Number";
      } else if (selectedSearchBy.value === "company_name_postcode") {
        return "Company Name and Postcode";
      } else if (selectedSearchBy.value === "mpan") {
        return "MPAN bottom line";
      } else if (selectedSearchBy.value === "mprn") {
        return "MPRN";
      }
      return "Postcode"; // Default label
    });

    const closeModal = () => {
      gasMprnData.value = [];
      electricInfos.value = [];
      gasInfos.value = [];
      gaspostcodeSearchResults.value = [];
      emit("close"); // Now correctly using emit
    };
    // Watch for changes in `searchPostcode` prop and update `localPostcode`
    watch(
      () => props.searchPostcode,
      (newPostcode) => {
        localPostcode.value = newPostcode;
      }
    );
    watch(
      () => props.selectedLabel,
      (newLabel) => {
        if (newLabel.toLowerCase() === "mprn") {
          selectedSearchBy.value = "mprn"; // Set MPRN as the default search by
        } else if (newLabel.toLowerCase() === "postcode") {
          selectedSearchBy.value = "postcode"; // Set MPRN as the default search by
        } else if (newLabel.toLowerCase() === "mpan") {
          selectedSearchBy.value = "mpan";
        } else {
          selectedSearchBy.value = "postcode";
        }
        // Add more conditions for other selectedLabel if needed
      }, {
        immediate: true,
      }
    );
    // Watch for changes in visibility to apply or remove the backdrop
    watch(
      () => props.isVisible,
      (newValue) => {
        if (newValue) {
          document.body.classList.add("modal-open");
          const backdrop = document.createElement("div");
          backdrop.className = "modal-backdrop fade show";
          document.body.appendChild(backdrop);
        } else {
          document.body.classList.remove("modal-open");
          const backdrop = document.querySelector(".modal-backdrop");
          if (backdrop) {
            backdrop.remove();
          }
        }
      }
    );

    const handleSearch = async (dataLabel) => {
      const paramsData = {
        search_type: selectedSearchBy.value, // Pass selected search type
        search_value: localPostcode.value, // Pass postcode (or other value)
      };

      // Dispatch the Vuex action with paramsData
      const response = await store.dispatch(
        "price/fetchLookupData",
        paramsData
      );
      if (dataLabel === "mprn") {
        gasMprnData.value = response[0];
      } else if (dataLabel === "mpan") {
        electricData.value = response[0];
      } else {
        gaspostcodeSearchResults.value = response;
      }
      console.log(electricData.value, "response");
    };

    const getCompanyDetails = async (id) => {
      const paramsData = {
        addressId: id, // Pass selected search type
        //   // U100020699213A0088278720G1M1900020113468N1PCR76EP
      };
      const response = await store.dispatch(
        "price/fetchSearchPropertyData",
        paramsData
      );
      if (response) {
        searchCompany.value = true;
        electricInfos.value = response.elecInfo;
        gasInfos.value = response.gasInfo;
      }
      console.log(response, "searchCompany.value = true;");
    };
    const backSearch = async () => {
      searchCompany.value = false;
    };
    // Initialize search options on component mount
    onMounted(() => {
      updateSearchBy();
    });

    return {
      electricData,
      gasMprnData,
      electricInfos,
      gasInfos,
      backSearch,
      searchCompany,
      getCompanyDetails,
      gaspostcodeSearchResults,
      store,
      handleSearch,
      localPostcode,
      lookupTypes,
      closeModal,
      selectedSearchBy,
      dynamicLabel,
      searchOptions,
      selectedLookupType,
      filteredSearchBy,
      updateSearchBy, // Expose this method if needed
    };
  },
};
</script>

<style scoped>
.modal {
  display: block;
  /* Ensure modal is displayed correctly */
}

.table_height {
  max-height: 428px;
}
</style>
