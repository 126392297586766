<template>
<!-- <div class="user-accordion-item">
  <a href="javascript:;" class="accordion-wrap collapsed" data-bs-toggle="collapse" data-bs-target="#refinement"><span><i class="ti ti-filter"></i></span>Refine</a>
  <div class="accordion-collapse collapse" id="refinement" data-bs-parent="#list-accord">
    <div class="content-collapse"> -->
<div class="toggle-row">

  <div class="row m-0">
    <div class="col-md-12 p-0">
      <h5 class="bg-teal p-2 mb-3 rounded text-center">Refine</h5>
    </div>
  </div>
  <div class="row tab-row-data m-0">
    <div class="col-md-12">
      <div class="row m-0">
        <h5 class="bg-teal p-2 mb-3 rounded text-center">Term/Year Toggle</h5>
        <div class="col-12 mb-3">
          <div class="d-flex align-items-center mod-toggle justify-content-center">
            <div class="status-toggle">
              <input type="checkbox" id="toggle" class="check" v-model="isYear" />
              <label for="toggle" class="checktoggle"></label>
            </div>
            <label for="toggle" class="checktoggle">{{ isYear ? 'Show as Year' : 'Show as Term' }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <!-- Filter by Term (Months) -->
      <div class="row m-0" v-if="!isYear">
        <h5 class="bg-teal p-2 mb-3 rounded text-center">Filter by term</h5>
        <div class="col-12">
          <CustomMinMaxSlider class="my-3" @update-min="handleMinUpdate" @update-max="handleMaxUpdate" :initialMinValue="minMonths" :initialMaxValue="maxMonths" />
          <p>Selected Min: {{ minMonths }}</p>
          <p>Selected Max: {{ maxMonths }}</p>
        </div>
      </div>
      <!-- Filter by Year (Checkboxes) -->
      <div class="row m-0 justify-content-center" v-if="isYear">
        <h5 class="bg-teal p-2 mb-3 rounded text-center">Filter by year</h5>
        <div class="col-2 text-center" v-for="year in years" :key="year">
          <div class="form-wrap">
            <label class="col-form-label">{{ year }}</label>
            <input type="checkbox" class="form-check-input year-checkbox" v-model="selectedYears" :value="year" @change="emitSelectedYears" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row m-0">
        <h5 class="bg-teal p-2 mb-3 rounded text-center">Pricing Window Filter
        </h5>
        <p>Hide suppliers that are outside of the pricing window</p>
        <div class="col-12">
          <div class="d-flex align-items-center mod-toggle ps-2">
            <div class="status-toggle">
              <input type="checkbox" id="toggleSup" class="check" />
              <label for="toggleSup" class="checktoggle"></label>
            </div>
            <label for="toggleSup" class="checktoggle">Hide Supplier</label>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex align-items-center mod-toggle ps-2">
            <div class="status-toggle">
              <input type="checkbox" id="toggleCommission" class="check" />
              <label for="toggleCommission" class="checktoggle"></label>
            </div>
            <label for="toggleCommission" class="checktoggle">Show total commission</label>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex align-items-center mod-toggle ps-2">
            <div class="status-toggle">
              <input type="checkbox" id="toggleUplift" class="check" />
              <label for="toggleUplift" class="checktoggle"></label>
            </div>
            <label for="toggleUplift" class="checktoggle">Show Uplift(pence/kWh)</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row m-0">
        <h5 class="bg-teal p-2 mb-3 rounded text-center">Filter by supplier
        </h5>
        <div class="col-12">
          <div class="d-flex align-items-center mod-toggle justify-content-center">
            <div class="status-toggle">
              <input type="checkbox" id="toggleQuotes" class="check" />
              <label for="toggleQuotes" class="checktoggle"></label>
            </div>
            <label for="toggleQuotes" class="checktoggle">Include Custom Quotes</label>
          </div>
        </div>
        <div class="col-12">
          <!-- Checkboxes List -->
          <div class="d-flex align-items-center mod-toggle" v-for="(supplier, index) in uniqueSuppliers" :key="index">
            <input type="checkbox" :id="index" class="form-check-input" v-model="checkboxStates[index]" @change="emitSelectedSuppliers" />
            <label :for="index" class="checktoggle ms-2">{{ supplier }}</label>
          </div>
          <!-- Toggle Button for Check All/Uncheck All -->
          <div class="d-flex justify-content-center my-3">
            <button class="btn btn-warning py-2" @click="toggleAll">
              <!-- {{ allChecked ? 'Uncheck All' : 'Check All' }} -->
              Toggle all
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row m-0">
        <h5 class="bg-teal p-2 mb-3 rounded text-center">Export settings
        </h5>
      </div>
    </div>
  </div>
</div>
<!-- </div>
  </div>
</div> -->
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  computed
} from "vue";
import {
  useStore,
  mapGetters
} from "vuex";
import {
  useRouter,
  useRoute
} from "vue-router";
import {
  Form,
  Field,
  ErrorMessage
} from "vee-validate";
import * as Yup from "yup";
import CustomMinMaxSlider from "./CustomMinMaxSlider.vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());

export default {
  props: {
    uniqueSuppliers: {
      type: Array,
      default: () => [], // Default to an empty array
    },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    CustomMinMaxSlider
  },
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      startdateTwo: currentDateTwo,
      dateFormat: "yyyy-MM-dd",
    };
  },
  setup(props, {
    emit
  }) {
    const store = useStore();
    const isYear = ref(false); // Toggle between year and term
    // Reactive variables to hold min and max values
    const minMonths = ref(12);
    const maxMonths = ref(60);
    // Methods to handle emitted events
    const handleMinUpdate = (newMin) => {
      minMonths.value = newMin;
      emit('min-months', newMin);
    };

    const handleMaxUpdate = (newMax) => {
      maxMonths.value = newMax;
      emit('max-months', newMax);
    };
    const years = ref([1, 2, 3, 4, 5]); // Years to show in checkboxes
    const selectedYears = ref([]); // Selected year checkboxes

    const checkboxStates = ref([]);

    // Computed property to check if all are checked
    const allChecked = computed(() => {
      return checkboxStates.value.length > 0 && checkboxStates.value.every(checked => checked);
    });

    // Initialize the checkbox states, check all by default
    const initializeCheckboxStates = () => {
      checkboxStates.value = Array(props.uniqueSuppliers.length).fill(true); // Check all by default
    };

    // Emit the selected suppliers based on checkbox states
    const emitSelectedSuppliers = () => {
      const selectedSuppliers = props.uniqueSuppliers.filter((_, index) => checkboxStates.value[index]);
      emit("update-selected-suppliers", selectedSuppliers);
    };

    // Toggle all checkboxes between checked/unchecked
    const toggleAll = () => {
      const newState = !allChecked.value;
      checkboxStates.value = checkboxStates.value.map(() => newState);
      emitSelectedSuppliers();
    };

    const emitSelectedYears = () => {
      emit("updateyears", selectedYears.value);
    };
    // Watch for changes in uniqueSuppliers to reinitialize the checkbox states
    watch(selectedYears, emitSelectedYears);
    watch(
      () => props.uniqueSuppliers,
      (newSuppliers) => {
        if (newSuppliers && newSuppliers.length) {
          initializeCheckboxStates();
          emitSelectedSuppliers();
        }
      }, {
        immediate: true
      } // Run immediately to initialize on first render
    );
    watch(isYear, (newValue) => {
      emit('update:isYear', newValue);
    });

    onMounted(async () => {
      selectedYears.value = [...years.value]; // Select all years by default
    emitSelectedYears(); // Emit the default selected years
      initializeCheckboxStates(); // Initialize the checkbox states
      emitSelectedSuppliers();
    });

    return {
      years,
      minMonths,
      maxMonths,
      emitSelectedYears,
      selectedYears,
      isYear,
      checkboxStates,
      allChecked,
      toggleAll,
      emitSelectedSuppliers,
      handleMaxUpdate,
      handleMinUpdate

    };
  },
};
</script>

<style scoped>
.tab-row-data {
  overflow: auto;
  max-height: 88vh;
}

.toggle-row {
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
  height: 100%;
}

.year-checkbox {
  height: 25px;
  width: 25px;
}
</style>
