<template>
<div class="kanban-wrapper leads-kanban-wrapper">
  <div v-for="(pipe, index) in modifiedPipeName" :key="index" class="kanban-list-items" :data-pipe-id="pipe.id">
    <!-- Kanban List Head -->
    <div class="kanban-list-head">
      <div class="row justify-content-between align-items-center">
        <div :class="pipe.DotClass" class="col-7">
          <h6>{{ pipe.name }}</h6>
          <!-- Display card count using the computed property -->
        </div>
        <div class="kanban-action-btns col-5 d-flex align-items-center justify-content-end pe-0">
          <span class="count-field">{{ getCardsCountByPipe(pipe.id) }} of {{ getCardsCountByPipe(pipe.id) }}</span>
          <router-link :to="{
                path: '/crm/create-meter-opportunity',
                query: { kanban: true, pipe_line: pipe.id },
              }" class="btn btn-primary p-1">
            <i class="ti ti-plus"></i>
          </router-link>
        </div>
      </div>
    </div>
    <!-- Kanban Cards -->
    <ul class="kanban-drag-wrap ui-sortable">
      <draggable :list="getCardsByPipe(pipe.id)" :group="{ name: 'kanban', pull: true, put: true }" @end="onDragEnd($event)" :data-pipe-id="pipe.id">
        <li v-for="card in getCardsByPipe(pipe.id)" :key="card.id" :data-card-id="card.id" :data-card-info="JSON.stringify(card)">
          <div class="kanban-card ui-sortable-handle position-relative">
            <!-- Card Details -->
            <div class="kanban-card-head">
              <span :class="card.CardClass"></span>
              <div class="kanban-card-title d-block">
                <div class="row align-items-center">
                  <div class="col-md-2">
                    <router-link :to="{
                          path: '/crm/meter-opportunities-edit',
                          query: { kanban: true, territory_id: pipe.id, org_Id: card.organization.id, opp_Id: card.id },
                        }">
                      <span>{{ card.initials }}</span>
                    </router-link>
                  </div>
                  <div class="col-md-6 p-0">
                    <h6>
                      <router-link :to="{
                            path: '/crm/organisation-details',
                            query: { kanban: true, territory_id: pipe.id, orgId: card.organization.id, opp_Id: card.id },
                          }">
                        {{ card.organization.name.toUpperCase() }}
                      </router-link>
                      <p :title="card.description" class="fw-normal description">{{ card.description }}</p>
                    </h6>
                  </div>
                  <div class="col-md-4">
                    <p class="text-end" title="Opportunity Nr number"># {{ card.meter_opportunity_nr_auto }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card Body -->
            <div class="kanban-card-body">
              <div class="row align-item-center card-price">
                <div class="col-4 pe-0">
                  <p v-if="card.date_of_sale" title="Date of Sale" class="background-class" :class="getDateClass(card.date_of_sale)">
                    <i class="ti ti-calendar-check"></i>
                    {{ card.date_of_sale }}
                  </p>
                </div>
                <div class="col-4 pe-0">
                  <p v-if="card.next_update_latest_date" title="Next Update Latest" class="background-class" :class="getDateClass(card.next_update_latest_date)">
                    <i class="ti ti-calendar-check"></i>
                    {{ card.next_update_latest_date }}
                  </p>
                </div>
                <div class="col-4">
                  <p class="text-end" title="SC Forcast Total Commision">
                    <i class="ion-cash"></i>
                    {{ card.sc_forecast_total_commission }}
                  </p>
                </div>
              </div>
            </div>
            <router-link class="edit-kanban-icon" :to="{
                  path: '/crm/meter-opportunities-edit',
                  query: { kanban: true, territory_id: pipe.id, org_Id: card.organization.id, opp_Id: card.id },
                }">
              <span><i class="ti ti-edit text-blue"></i></span>
            </router-link>
          </div>
        </li>
      </draggable>
    </ul>
  </div>
</div>
</template>

<script>
import {
  VueDraggableNext
} from "vue-draggable-next";
import {
  ref,
  onMounted,
  computed,
  watchEffect
} from "vue";
import {
  useRouter
} from "vue-router";
import {
  useStore
} from "vuex";

export default {
  props: {
    searchBoardQuery: String,
    filters: Object,
  },
  components: {
    draggable: VueDraggableNext,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const modifiedPipeName = ref([]);
    const opportunities = computed(() => store.getters["opportunity/getOppListData"]);
    const pipelineLevels = computed(() => store.getters["opportunity/getPipelineLevels"]);

    // Get today's date without time
    const today = new Date().setHours(0, 0, 0, 0);

    // Computed property for date class
    const getDateClass = (dateOfSale) => {
      const saleDate = new Date(dateOfSale).setHours(0, 0, 0, 0);
      if (saleDate === today) {
        return "yellow"; // Date is today
      } else if (saleDate < today) {
        return "red"; // Date is before today
      } else {
        return "green"; // Date is after today
      }
    };

    // Transform pipeline names
    watchEffect(() => {
      if (pipelineLevels.value && pipelineLevels.value.length) {
        modifiedPipeName.value = pipelineLevels.value.map((item) => ({
          ...item,
          name: item.name.replace(/\[\d+\]\s*/, ""), // Remove "[n]" from the name
        }));
      }
    });

    const fetchBoardOpportunities = async () => {
      const params = {
        search: props.searchBoardQuery, // Use the correct prop
      };
      await store.dispatch("opportunity/fetchOpportunitiesLists", params);
    };

    onMounted(() => {
      fetchBoardOpportunities();
    });

    // Get cards by pipe ID
    const getCardsByPipe = (pipeID) => {
      return Array.isArray(opportunities.value) ?
        opportunities.value.filter((card) => card.pipeline_level === pipeID) :
        [];
    };

    // Get card count by pipe ID
    const getCardsCountByPipe = (pipeID) => {
      return getCardsByPipe(pipeID).length;
    };

    const onDragEnd = async (event) => {
      const draggableCard = event.item;
      const cardInfo = JSON.parse(draggableCard.dataset.cardInfo);
      const targetElement = event.to;
      const draggableComponent = targetElement.__draggable_component__;
      const newPipelineId = draggableComponent.$attrs["data-pipe-id"];

      const form = ref({
        id: cardInfo.id,
        pipeline_level: newPipelineId,
      });

      const response = await store.dispatch("opportunity/updateOpportunity", form.value);
      if (response.status) {
        await store.dispatch("opportunity/fetchOpportunitiesLists");
      }
    };

    return {
      modifiedPipeName,
      fetchBoardOpportunities,
      getCardsByPipe,
      getCardsCountByPipe,
      onDragEnd,
      getDateClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.kanban-card {
  &:hover {
    .edit-kanban-icon {
      display: block;
    }
  }
}

.edit-kanban-icon {
  position: absolute;
  top: 5px;
  right: 10px;
  display: none;
  border: 1px solid #4a00e54a;
  border-radius: 50%;
  padding: 1px;
  height: 25px;
  width: 25px;
  text-align: center;
}

.background-class {
  border-radius: 20px;
  padding: 2px 5px;
}

.yellow {
  color: #db7f28;
  background-color: rgb(255 165 0 / 15%);
  font-weight: 600;
}

.red {
  color: red;
  background: rgb(255 0 0 / 15%);
}

.green {
  color: green;
  background: rgb(0 128 0 / 15%);
}

.card-price {
  p {
    font-size: 10px;
  }
}

.count-field {
  background-color: #00918e33;
  color: #036664;
  padding: 2px 10px;
  border-radius: 15px;
  margin-right: 10px;
  font-size: 12px;
}
.description {
  white-space: nowrap;        /* Prevents the text from wrapping */
  overflow: hidden;           /* Ensures the overflow is hidden */
  text-overflow: ellipsis;    /* Adds the ellipsis ("...") when the text overflows */
  max-width: 100%;            /* Adjust as needed to control the width of the text */
}
</style>
