<template>
  <div class="card" v-if="suppliersRateList.length > 0">
    <div class="row mx-0 p-3">
      <div class="col-12 p-0 mb-3">
        <ul>
          <li class="text-end">
            <a
              href="javascript:void(0);"
              title="Export as PDF"
              @click="downloadPdf"
              class="btn documnet-btn btn-primary export_btn p-1"
            >
              <i class="ti ti-file-type-pdf"></i>
            </a>
            <a
              href="javascript:void(0);"
              title="Export as Excel"
              @click="downloadExcel"
              class="btn documnet-btn btn-primary export_btn p-1 ms-2"
            >
              <i class="ti ti-file-type-xls"></i>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-12 table-responsive mb-0 p-0">
        <table class="table border">
          <thead>
            <tr>
              <th scope="col" class="bg-teal text-white">Unit Rate Uplift</th>
              <th
                scope="col"
                class="bg-teal max_width_th text-white text-center"
              >
                Standing Charge Uplift (pence/day)
              </th>
              <th scope="col" class="bg-teal text-white text-center">
                Supplier
              </th>
              <th scope="col" class="bg-teal text-white text-center">
                Product
              </th>
              <th scope="col" class="bg-teal text-white text-center">Term</th>
              <th
                scope="col"
                class="bg-teal max_width_th text-white text-center"
              >
                Day Rate (p/kWh)
              </th>
              <th
                scope="col"
                class="bg-teal max_width_th text-white text-center"
                v-if="isElectric"
              >
                Night Rate (p/kWh)
              </th>
              <th
                scope="col"
                class="bg-teal max_width_th text-white text-center"
                v-if="isElectric"
              >
                Weekend Rate (p/kWh)
              </th>
              <th
                scope="col"
                class="bg-teal max_width_th text-white text-center"
              >
                Standing Charge (p/day)
              </th>
              <th scope="col" class="bg-teal text-white text-center">
                Annual Cost
              </th>
              <th
                v-if="isCurrentSupplierCost"
                scope="col"
                class="bg-teal text-white text-center"
              >
                Difference
              </th>
              <th
                v-if="isCurrentSupplierCost"
                scope="col"
                class="bg-teal text-white text-center"
              >
                % Difference
              </th>
              <th scope="col" class="bg-teal text-white text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style="background-color: #fee5b3"
                class="text-center py-1 text-dark"
                :colspan="
                  isCurrentSupplierCost
                    ? isElectric
                      ? '13'
                      : '11'
                    : isElectric
                    ? '11'
                    : '9'
                "
              >
                <b>Current Supplier</b>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td class="text-center"></td>
              <td class="text-center"></td>
              <td class="text-center">
                {{ formData.quoteDetails.CurrentSupplier }}
              </td>
              <td class="text-center">Default</td>
              <td class="text-center"></td>
              <td class="text-center">{{ formData.quoteDetails.OldRate }}</td>
              <td class="text-center" v-if="isElectric"></td>
              <td class="text-center" v-if="isElectric"></td>
              <td class="text-center">
                {{ formData.quoteDetails.StandingCharge }}
              </td>
              <td class="text-center">
                <b>£{{ formData.quoteDetails.CurrentCost }}</b>
              </td>
              <td class="text-center" v-if="isCurrentSupplierCost"></td>
              <td class="text-center" v-if="isCurrentSupplierCost"></td>
              <td class="text-center"></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td
                class="text-center text-dark py-1"
                :colspan="
                  isCurrentSupplierCost
                    ? isElectric
                      ? '13'
                      : '11'
                    : isElectric
                    ? '11'
                    : '9'
                "
                style="background-color: #fee5b3"
              >
                <b>Your Price</b>
              </td>
            </tr>
          </tbody>
          <tbody v-for="supplier in filteredSuppliers" :key="supplier.Id">
            <tr>
              <td class="text-center">
                {{
                  supplier.Uplift ? parseFloat(supplier.Uplift).toFixed(1) : "-"
                }}
              </td>
              <td class="text-center">
                {{ supplier.StandingChargeUplift || "0" }}
              </td>
              <td class="text-center">{{ supplier.Supplier }}</td>
              <td class="text-center">{{ supplier.PlanType || "Default" }}</td>
              <td class="text-center">
                {{ isYear ? supplier.Term / 12 + " y" : supplier.Term + " m" }}
              </td>
              <td class="text-center">{{ supplier.DayUnitrate }}</td>
              <td class="text-center" v-if="isElectric">
                {{ supplier.NightUnitrate }}
              </td>
              <td class="text-center" v-if="isElectric">
                {{ supplier.WendUnitrate }}
              </td>
              <td class="text-center">{{ supplier.StandingCharge }}</td>
              <td class="text-center fw-bold">{{ supplier.AnnualPrice }}</td>
              <td
                v-if="isCurrentSupplierCost"
                class="text-center"
                :class="priceClass(supplier.AnnualPrice)"
              >
                {{ priceDifference(supplier.AnnualPrice) }}
              </td>
              <td
                v-if="isCurrentSupplierCost"
                class="text-center"
                :class="priceClass(supplier.AnnualPrice)"
              >
                {{ priceDiffPercent(supplier.AnnualPrice) }}
              </td>
              <td class="text-center"></td>
            </tr>
            <tr>
              <td :colspan=" isYear ? '10' : '13'" v-if="(supplier.ExtraInfo || supplier.PlanType)" class="w-100 text-danger extra_info text-center p-0"><span class="me-1" v-if="supplier.PlanType">Plan Type: {{ supplier.PlanType }}.</span> <span v-if="supplier.ExtraInfo">{{ supplier.ExtraInfo}}</span></td>
            </tr>
          </tbody>
          <tbody v-if="filteredSuppliers.length === 0">
            <tr>
              <td :colspan="isYear ? '11' : '9'" class="w-100 text-center">
                No Data Found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { ref, watch, computed } from "vue";

export default {
  props: {
    suppliersRateList: {
      type: Array,
      default: () => [],
    },
    selectedSuppliers: {
      type: Array,
      default: () => [],
    },
    isElectric: {
      type: Boolean,
      default: false,
    },
    isYear: {
      type: Boolean,
      default: false,
    },
    selectedYears: {
      type: Array,
      default: () => [],
    },
    minMonths: {
      type: Number,
      default: 12,
    },
    maxMonths: {
      type: Number,
      default: 60,
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const diffPercent = ref(null);

    const isCurrentSupplierCost = computed(() => {
      return (
        props.formData.quoteDetails &&
        props.formData.quoteDetails.CurrentCost &&
        parseFloat(props.formData.quoteDetails.CurrentCost) !== 0
      );
    });

    const filteredSuppliers = computed(() => {
      return props.suppliersRateList.filter((supplier) => {
        const supplierInSelected = props.selectedSuppliers.includes(
          supplier.Supplier
        );
        if (props.isYear) {
          const termInYears = supplier.Term / 12;
          return (
            supplierInSelected && props.selectedYears.includes(termInYears)
          );
        } else {
          const withinTermRange =
            supplier.Term >= props.minMonths &&
            supplier.Term <= props.maxMonths;
          return supplierInSelected && withinTermRange;
        }
      });
    });

    const calculateDifference = (annualPrice) => {
      if (!annualPrice || !props.formData.quoteDetails.CurrentCost) {
        return 0;
      }

      const parsedPrice = parseFloat(annualPrice.replace(/[^0-9.-]+/g, ""));
      const currentCost = parseFloat(props.formData.quoteDetails.CurrentCost);

      if (isNaN(parsedPrice) || isNaN(currentCost)) {
        return 0;
      }

      return parsedPrice - currentCost;
    };

    const priceDiffPercent = (price) => {
      const currentCost = parseFloat(props.formData.quoteDetails.CurrentCost);
      const diffRate = calculateDifference(price);

      // Ensure currentCost is not zero to avoid division by zero errors
      if (isNaN(currentCost) || currentCost === 0) {
        return 0;
      }

      const percentage = (diffRate * 100) / currentCost;
      return percentage < 0
        ? `${Math.round(percentage)}%`
        : `+${Math.round(percentage)}%`;
    };

    const priceClass = (annualPrice) => {
      const diffRate = calculateDifference(annualPrice);
      return diffRate < 0 ? "text-success" : "text-danger";
    };

    const priceDifference = (supplierAnnualPrice) => {
      const diffRate = calculateDifference(supplierAnnualPrice);
      const formattedDiffRate = diffRate.toFixed(2);
      return diffRate < 0
        ? `-£${Math.abs(formattedDiffRate)}`
        : `+£${formattedDiffRate}`;
    };

    const downloadPdf = async () => {
      // Get annual usage and renewal date from local storage
      const annualUsage =
        localStorage.getItem(
          props.isElectric ? "elec_annual_usage" : "annual_usage"
        ) || 0;
      const renew =
        localStorage.getItem(
          props.isElectric ? "elec_renewDate" : "renewDate"
        ) || "";

      // Dynamically collect headers from the table
      const tableHeaders = Array.from(
        document.querySelectorAll("table thead th")
      ).map((th) => th.innerText.trim());

      // Filter out the headers you don't want
      const filteredHeaders = tableHeaders.filter((header) => {
        return ![
          "Unit Rate Uplift",
          "Standing Charge Uplift (pence/day)",
          "Actions",
          "Product",
        ].includes(header);
      });

      // Check if the "Difference" and "%" headers are present
      const hasDifferenceHeader = filteredHeaders.includes("Difference");
      const hasPercentHeader = filteredHeaders.includes("% Difference");
      // Construct formData with dynamic headers
      const formData = {
        type: props.isElectric ? "electricity" : "gas",
        supplier: "current",
        account_number: "123456789000",
        annual_usage: annualUsage,
        reference_number: "#1",
        renewal_date: renew,
        headers: filteredHeaders, // Pass the dynamically collected headers here
        current: {
          supplier: props.formData.quoteDetails.CurrentSupplier,
          term: "",
          day_rate: "",
          night_rate: "",
          weekend_rate: "",
          standing_charge: props.formData.quoteDetails.StandingCharge,
          kva: "",
          annual_cost:
          props.formData.quoteDetails.CurrentCost || 0,
          difference: "",
          percent_difference: "",
        },
        quotes: filteredSuppliers.value.map((supplier) => ({
          supplier: supplier.Supplier,
          term: props.isYear ? String(supplier.Term / 12) : supplier.Term,
          day_rate: supplier.DayUnitrate,
          night_rate: supplier.NightUnitrate || "",
          weekend_rate: supplier.WendUnitrate || "",
          standing_charge: supplier.StandingCharge,
          annual_cost: supplier.AnnualPrice,
          kva: "",
          // Conditionally pass priceDifference if "Difference" header exists
          difference: hasDifferenceHeader
            ? priceDifference(supplier.AnnualPrice)
            : "",
          // Conditionally pass priceDiffPercent if "%" header exists
          percent_difference: hasPercentHeader
            ? priceDiffPercent(supplier.AnnualPrice)
            : "",
            extra_info: `Plan Type: ${supplier.PlanType || ""} ${supplier.ExtraInfo || ""}`,
        })),
      };
      const response = await store.dispatch("price/downloadPdf", formData);

      const downloadUrl = response.data.url;
      if (downloadUrl) {
        // Open the PDF in a new tab
        window.open(downloadUrl, "_blank");
      } else {
        console.error("PDF download URL not found");
      }
    };

    const downloadExcel = async () => {
      const tableHeaders = Array.from(
        document.querySelectorAll("table thead th")
      ).map((th) => th.innerText.trim());

      // Check if the "Difference" and "%" headers are present
      const hasDifferenceHeader = tableHeaders.includes("Difference");
      const hasPercentHeader = tableHeaders.includes("% Difference");

      const excelFormData = {
        type: props.isElectric ? "electricity" : "gas",
        quotes: filteredSuppliers.value.map((supplier) => ({
          supplier: supplier.Supplier,
          term: props.isYear ? supplier.Term / 12 : supplier.Term,
          day_rate: supplier.DayUnitrate,
          standing_charge: supplier.StandingCharge,
          annual_cost: supplier.AnnualPrice,
          saving: hasDifferenceHeader
            ? priceDifference(supplier.AnnualPrice)
            : "",
          percent: hasPercentHeader
            ? priceDiffPercent(supplier.AnnualPrice)
            : "",
          percent_one: "",
          percent_two: "",
        })),
      };

      try {
        const response = await store.dispatch(
          "price/downloadExcel",
          excelFormData
        );
        const downloadUrl = response.data.url;

        if (downloadUrl) {
          // Create a hidden anchor element and trigger the download
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "pricing_comparison.xlsx"); // Set the filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // Clean up after triggering download
        } else {
          console.error("Download URL not found");
        }
      } catch (error) {
        console.error("Error downloading the Excel file", error);
      }
    };

    return {
      downloadExcel,
      priceDiffPercent,
      filteredSuppliers,
      downloadPdf,
      priceDifference,
      priceClass,
      diffPercent,
      isCurrentSupplierCost,
    };
  },
};
</script>

<style lang="scss" scoped>
.max_width_th {
  max-width: 140px;
  white-space: break-spaces;
}

/* Remove borders and hover effect from tr */
tr {
  border: none;
  transition: none;
  /* No more hover effects */
}

td {
  border-bottom-width: 0;
}

/* Add border and hover effect to tbody */
tbody {
  border: 1px solid #ccc;
  /* Add border to tbody */
  transition: background-color 0.3s ease;
  /* Smooth hover effect */
}

tbody:hover {
  tr,
  td {
    background-color: #f0f0f0;
    /* Change background on hover */
  }
}

.extra_info {
  font-size: 10px;
}
.documnet-btn {
  font-size: 28px;
}
</style>
