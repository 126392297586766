<template>
<div>
  <div class="row tab-row-data m-0">
    <div class="col">
      <h5 class="bg-teal p-2 mb-3 rounded text-center">Basic details</h5>
      <div class="row">
        <div class="col-12">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Postcode <i class="ti ti-search" style="cursor: pointer;" @click="openModal(formData.quoteDetails.PostCode, 'postcode')"></i></label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.PostCode" />
            <span class="text-danger" v-if="isElecNotValid && (formData.quoteDetails.PostCode === '')">Postcode is required</span>
          </div>
        </div>
        <div class="col-12">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Top Line</label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.ElectricSupply.MPANTop" />
            <span class="text-danger" v-if="isElecNotValid && (formData.quoteDetails.ElectricSupply.MPANTop === '')">Mpan top is required</span>
          </div>
        </div>
        <div class="col-12">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Bottom Line <i class="ti ti-search" style="cursor: pointer;" @click="openModal(formData.quoteDetails.ElectricSupply.MPANBottom, 'mpan')"></i></label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.ElectricSupply.MPANBottom" />
            <span class="text-danger" v-if="isElecNotValid && (formData.quoteDetails.ElectricSupply.MPANBottom === '')">Mpan bottom is required</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <h5 class="bg-teal p-2 mb-3 rounded text-center">Usage details</h5>
      <div class="row">
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Annual Day Usage (kWh)
            </label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.ElectricSupply.DayConsumption.Amount" @blur="calculateCurrentCost" />
            <span class="text-danger" v-if="isElecNotValid && (formData.quoteDetails.ElectricSupply.DayConsumption.Amount === 0)">Annual Day Usage is required</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Old day rate (pence/kWh)
            </label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.ElectricSupply.OldDayConsumption.Amount" @blur="calculateCurrentCost" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Annual Night Usage (kWh)
            </label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.ElectricSupply.NightConsumption.Amount" @blur="calculateCurrentCost" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Old Night Rate (pence/kWh)
            </label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.ElectricSupply.OldNightConsumption.Amount" @blur="calculateCurrentCost" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Annual Weekend Usage (kWh)
            </label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.ElectricSupply.WendConsumption.Amount" @blur="calculateCurrentCost" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Old Weekend Rate (pence/kWh)
            </label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.ElectricSupply.OldWendConsumption.Amount" @blur="calculateCurrentCost" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Feed-In Tariff (FiT) (pence/kWh)
            </label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.FeedTariff" @blur="calculateCurrentCost" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Standing Charge (pence/day)
            </label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.StandingCharge" @blur="calculateCurrentCost" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">kVA
            </label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.ElectricSupply.KvaConsumption.Amount" @blur="calculateCurrentCost" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">kVA Rate (p/day)
            </label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.ElectricSupply.KvadayConsumption" @blur="calculateCurrentCost" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Current Cost (£)
            </label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.CurrentCost" @blur="calculateCurrentCost" />
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <h5 class="bg-teal p-2 mb-3 rounded text-center">Contract details</h5>
      <div class="row">

        <div class="col-md-12" ref="dropdownContainer">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Current Supplier</label>
            <Field name="organisation_id" type="text" class="form-control" placeholder="Search organisation name / postcode" v-model="searchQuery" @keydown.enter="searchSupplier" @input="onInput" @focus="showDropdown" />
            <ul v-if="filteredSuppliers.length" class="dropdown-menu supplier-data shadow show pe-0">
              <li class="dropdown-listing">
                <ul>
                  <li v-for="res in filteredSuppliers" :key="res.id" @click="selectSupplier(res)" class="dropdown-item" style="cursor: pointer;">
                    {{ res.name  }}
                  </li>
                </ul>
              </li>
            </ul>
            <span class="text-danger" v-if="isElecNotValid && (formData.quoteDetails.CurrentSupplier === '')">Current supplier is required</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Payment Type</label>
            <Field as="select" name="new_supplier_id" class="form-select" v-model="formData.quoteDetails.PaymentMethod">
              <option v-for="res in dropdownOptions.contract_payment_type" :key="res.value" :value="res.value">
                {{ res.text }}
              </option>
            </Field>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Renewal Date
            </label>
            <div class="icon-form">
              <date-picker placeholder="" class="datetimepicker form-control" :editable="true" :clearable="false" :input-format="dateFormat" v-model="formData.quoteDetails.ElectricSupply.ContractRenewalDate" />
              <span class="form-icon"><i class="ti ti-calendar-check"></i></span>
            </div>
            <span class="text-danger" v-if="isElecNotValid && (formData.quoteDetails.ElectricSupply.ContractRenewalDate === '')">Renewal date is required</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Unit Rate Uplift</label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.Uplift" />
            <!-- <Field as="select" name="supplier_id" class="form-select">
              <option value="">-----Select-----</option>
              <option v-for="res in dropdownOptions.contract_unit_rate_uplift" :key="res.value" :value="res.value">
                {{ res.text }}
              </option>
            </Field> -->
            <span class="text-danger" v-if="isElecNotValid && (formData.quoteDetails.Uplift === null) ">Unit Rate Uplift is required</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Email</label>
            <input type="text" class="form-control" />
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <h5 class="bg-teal p-2 mb-3 rounded text-center">Additional details</h5>
      <div class="row">
        <div class="col-md-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Credit Score</label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.Settings[1].value" />
            <span class="text-danger" v-if="isElecNotValid && (formData.quoteDetails.Settings[1].value === '0')">Credit Score is required</span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Measurement Class
            </label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.ElectricSupply.MeasurementClass" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">Meter Type <i class="ti ti-search" style="cursor: pointer;" @click="openModal(formData.quoteDetails.ElectricSupply.MeterType)"></i></label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.ElectricSupply.MeterType" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-wrap mb-2">
            <label class="col-form-label">SSC <i class="ti ti-search" style="cursor: pointer;" @click="openModal(formData.quoteDetails.ElectricSupply.StandardSettlementConfig)"></i></label>
            <input type="text" class="form-control" v-model="formData.quoteDetails.ElectricSupply.StandardSettlementConfig" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<look-up-modal :isVisible="isModalVisible" :searchPostcode="selectedPostcode" :selectedLabel="selectedLabel" @close="closeModal"></look-up-modal>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  computed,
} from "vue";
import {
  useStore
} from "vuex";
import {
  useRouter,
  useRoute
} from "vue-router";
import {
  Form,
  Field,
  ErrorMessage
} from "vee-validate";

export default {
  props: {
    isElecNotValid: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      dateFormat: "yyyy-MM-dd",
    };
  },
  setup(props, {
    emit
  }) {
    const store = useStore();
    const filteredSuppliers = ref([]);
    const searchQuery = ref('');
    const dropdownContainer = ref(null);
    const selectedPostcode = ref('');
    const selectedLabel = ref('');
    const isModalVisible = ref(false);

    const supplierOptions = computed(
      () => store.getters["price/getSuppliers"]
    );
    const dropdownOptions = computed(
      () => store.getters["price/getDropdowns"]
    );
    const formData = ref({
      mainProductService: "elec",
      quoteDetails: {
        PostCode: "",
        Uplift: null,
        PaymentMethod: "Monthly Direct Debit",
        CurrentSupplier: "",
        OldRate: "0.00",
        StandingCharge: "0.00",
        FeedTariff: "0.00",
        CurrentCost: "0.00",
        EnableQuoteEmail: false,
        QuoteReference: "",
        Email: "",
        BusinessType: "LTD",
        ElectricSupply: {
          DayConsumption: { //Annual Day Usage (kWh)
            Amount: 0,
            Type: "Day"
          },
          OldDayConsumption: { //Annual Day Usage (kWh)
            Amount: 0,
            Type: "Day"
          },
          NightConsumption: { //Annual Night Usage
            Amount: 0,
            Type: "Night"
          },
          OldNightConsumption: { //Annual Day Usage (kWh)
            Amount: 0,
            Type: "Day"
          },
          WendConsumption: {
            Amount: 0,
            Type: "Weekend"
          },
          OldWendConsumption: {
            Amount: 0,
            Type: "OldWeekend"
          },
          KvaConsumption: {
            Amount: 1,
          },
          MPANTop: "",
          MPANBottom: "",
          ContractRenewalDate: "", // Renewal Date
          KvadayConsumption: '',
          MeasurementClass: "",
          StandardSettlementConfig: "",
          SmartMeter: "false",
          MeterType: null
        },
        Settings: [{
            key: "upliftBehaviour",
            value: "closest"
          },
          {
            key: "CreditScore",
            value: ""
          },
          {
            key: "ShowNoQuoteReason",
            value: "True"
          }
        ],
      },
    });
    const onInput = () => {
      searchSupplier();
    };

    const searchSupplier = () => {
      const query = searchQuery.value.toLowerCase();
      if (query) {
        filteredSuppliers.value = supplierOptions.value.filter((org) => {
          const nameMatch = org.name.toLowerCase().includes(query);
          return nameMatch;
        });
      } else {
        // If the query is empty, hide the dropdown
        filteredSuppliers.value = [];
      }
    };

    const showDropdown = () => {
      // Show all organisations when the input is focused (clicked)
      if (!searchQuery.value) {
        filteredSuppliers.value = supplierOptions.value;
      }
    };

    const selectSupplier = (organisation) => {
      formData.value.quoteDetails.CurrentSupplier = organisation.name;
      searchQuery.value = organisation.name;

      filteredSuppliers.value = [];
    };

    const openModal = (code, label) => {
      selectedPostcode.value = code; // Assign the clicked value to selectedPostcode
      selectedLabel.value = label;
      isModalVisible.value = true;
    };

    const closeModal = () => {
      isModalVisible.value = false;
    };

    const hideDropdown = () => {
      filteredSuppliers.value = [];
    };

    const handleClickOutside = (event) => {
      // Check if the click happened outside the dropdown container
      if (dropdownContainer.value && !dropdownContainer.value.contains(event.target)) {
        hideDropdown();
      }
    };
    const emitFormData = () => {
      emit("submit-elctric-form", formData.value);
    };
    const calculateCurrentCost = () => {
      // Helper function to safely parse and handle NaN
      const parseAmount = (value) => parseFloat(value) || 0;

      // Extracting form data values for easier access
      const electricSupply = formData.value.quoteDetails.ElectricSupply;
      const feedTariff = parseAmount(formData.value.quoteDetails.FeedTariff);
      const standingCharge = parseAmount(formData.value.quoteDetails.StandingCharge);

      // Old rate annual price calculations
      const annualPrice = parseAmount(electricSupply.DayConsumption.Amount);
      const oldDayPrice = parseAmount(electricSupply.OldDayConsumption.Amount);
      const annual_rate = (annualPrice * oldDayPrice) / 100;
      console.log(annual_rate, "1. annual_rate");

      const annualNightPrice = parseAmount(electricSupply.NightConsumption.Amount);
      const oldNightPrice = parseAmount(electricSupply.OldNightConsumption.Amount);
      const night_rate = (annualNightPrice * oldNightPrice) / 100;
      console.log(night_rate, "2. night_rate");

      const annualWeekendPrice = parseAmount(electricSupply.WendConsumption.Amount);
      const oldWeekendPrice = parseAmount(electricSupply.OldWendConsumption.Amount);
      const weekend_rate = (annualWeekendPrice * oldWeekendPrice) / 100;
      console.log(weekend_rate, "3. weekend_rate");

      const kvaConsumption = parseAmount(electricSupply.KvaConsumption.Amount);
      const kvaDayConsumption = parseAmount(electricSupply.KvadayConsumption);
      const kvaConsumption_rate = (kvaConsumption * kvaDayConsumption * 365) / 100;
      console.log(kvaConsumption_rate, "4. kvaConsumption_rate");

      // Feed tariff calculations
      const feed_annual_rate = (annualPrice * feedTariff) / 100;
      const feed_value_rate = feed_annual_rate + (annualNightPrice * (oldNightPrice + feedTariff)) / 100;
      console.log(feed_value_rate, "5. feed_value_rate");

      // Standing charge calculation
      const standingChargeCost = (standingCharge * 365) / 100;

      // Calculate total cost based on feed_value_rate
      let totalCost;
      if (feed_value_rate !== 0) {
        totalCost = annual_rate + weekend_rate + standingChargeCost + kvaConsumption_rate + feed_value_rate;
      } else {
        totalCost = annual_rate + night_rate + weekend_rate + standingChargeCost + kvaConsumption_rate + feed_value_rate;
      }

      // Update the current cost in formData
      formData.value.quoteDetails.CurrentCost = totalCost.toFixed(2);
    };

    onMounted(async () => {
      emitFormData();
      document.addEventListener('click', handleClickOutside);
      store.dispatch("price/fetchSuppliers");
    });

    return {
      calculateCurrentCost,
      emitFormData,
      selectedLabel,
      selectedPostcode,
      openModal,
      closeModal,
      isModalVisible,
      filteredSuppliers,
      dropdownContainer,
      handleClickOutside,
      hideDropdown,
      showDropdown,
      searchSupplier,
      searchQuery,
      selectSupplier,
      onInput,
      formData,
      supplierOptions,
      dropdownOptions,
    };
  },
};
</script>

<style scoped>
.tab-row-data {
  /* overflow-x: auto; */
  flex-wrap: inherit;
}

.supplier-data {
  width: 280px;
  max-height: 350px;
  overflow: auto;
}

.col-form-label {
  font-size: 12px;
}

.form-control {
  font-size: 12px;
}

.not-valid {
  border-color: #FC0027;
}
</style>
